import React, { useEffect } from 'react';

import { Body, Footer, Page } from '@sigeco/tools'
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';

import PermissionsFilter from '../components/permissions/PermissionsFilter';
import PermissionsList from '../components/permissions/PermissionsList';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../store/permissions/actions';

const Permissions = ({ data, loading, btnSave, onClose, onPermissionsList, restartState, setTitle }) => {
    
  useEffect(() => {
        setTitle('Búsqueda');
      // eslint-disable-next-line
    }, []);

    function onBtnClose() {
        restartState();
        onClose();
    }
    
    return (
        <Page>
            <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={loading} transitionDuration={0}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Body>
                <PermissionsFilter />
                <PermissionsList /> 
            </Body>
            <Footer>
              <Button 
                color="primary" 
                size="small" 
                variant="contained" 
                onClick={() => onPermissionsList()}
                startIcon={<Icon className="fas fa-search"/>}
              >
                Buscar
              </Button>
              {data.length > 0 && <Button 
                color="primary" 
                size="small" 
                variant="contained" 
                onClick={btnSave}
                startIcon={<Icon className="fas fa-save"/>}
              >
                Guardar
              </Button>}
              <Button 
                color="primary" 
                size="small" 
                variant="contained" 
                onClick={onBtnClose} 
                startIcon={<Icon className="fas fa-door-open"/>}
              >
                Salir
              </Button>
            </Footer>
        </Page>
    );
}

const mapStateToProps = state => ({
  data:  state.permissions.table.data,
  fotmState: state.permissions.formState,
  loading: state.permissions.loading
});

export default connect(
  mapStateToProps,
  dispatch => bindActionCreators({...actionCreators}, dispatch)
)(Permissions);
