import React, { useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation, useRouteMatch } from "react-router-dom";

import { Body, Footer, Page } from '@sigeco/tools';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';

import UserCompany from '../components/user/UserCompany';
import UserFilter from '../components/user/UserFilter';
import UserForm from '../components/user/UserForm';
import UserList from '../components/user/UserList';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../store/user/actions';

const User = ({ formState, loading, usuarioId, btnDelete, btnSave, btnSearch, onClose, onExport, restartState, setFormState, setTitle }) => {
  const location = useLocation();
  const match = useRouteMatch();

  useEffect(() => {
    switch(match.path) {
      case '/seguridad/usuario/crear':
        setTitle('Crear Usuario');
        if(formState !== 'new') {
          setFormState('new');
        }
        break;
      case '/seguridad/usuario/:id':
        setFormState('view', match.params['id']);
        setTitle('Visualizar Usuario');
        break;
      case '/seguridad/usuario/:id/editar':
        setFormState('edit', match.params['id']);
        setTitle('Editar Usuario');
        break;
      case '/seguridad/usuario/:id/resetear':
        setFormState('restart', match.params['id']);
        setTitle('Restablecer contraseña');
        break;
      default:
        setFormState('search');
        setTitle('Búsqueda');
        break;
    }
  // eslint-disable-next-line
  }, [match.path]);

  function onBtnClose() {
    restartState();
    onClose();
  }

  return (
    <Page>
      <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={loading} transitionDuration={0}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Body>
        {formState === 'search' && <UserFilter />}
        {formState === 'search' && <UserList />}
        {formState !== 'search' &&  <UserForm />}
        {formState !== 'search' &&  <UserCompany />}
      </Body>
      <Footer>
        {formState === 'view' && <LinkContainer to={{ pathname: `/seguridad/usuario/${usuarioId}/resetear`, search: location.search, hash: location.hash }}>
          <Button 
            color="primary" 
            size="small" 
            variant="contained"
            startIcon={<Icon className="fas fa-key"/>}
          >
            Recuperar contraseña
          </Button>
        </LinkContainer>}
        {formState === 'search' && <Button
          color="primary" 
          size="small" 
          variant="contained" 
          onClick={onExport}
          startIcon={<Icon className="fas fa-file-excel"/>}
        >
          Exportar
        </Button>}
        {formState === 'search' && <Button
          color="primary" 
          size="small" 
          variant="contained" onClick={() => btnSearch()}
          startIcon={<Icon className="fas fa-search"/>}
        >
          Buscar
        </Button>}
        {(formState === 'search' || formState === 'view') && <LinkContainer to={{ pathname: '/seguridad/usuario/crear', search: location.search, hash: location.hash }}>
          <Button
            color="primary" 
            size="small" 
            variant="contained"
            startIcon={<Icon className="fas fa-file"/>}
          >
            Crear
          </Button>
        </LinkContainer>}
        {formState === 'view' && <LinkContainer to={{ pathname: `/seguridad/usuario/${usuarioId}/editar`, search: location.search, hash: location.hash }}>
          <Button
            color="primary" 
            size="small" 
            variant="contained"
            startIcon={<Icon className="fas fa-edit"/>}
          >
            Modificar
          </Button>
        </LinkContainer>}
        {formState === 'view' && <Button 
          color="primary" 
          size="small" 
          variant="contained" 
          onClick={btnDelete}
          startIcon={<Icon className="fas fa-trash"/>}
        >
          Eliminar
        </Button>}
        {formState !== 'search' && formState !== 'view' && <Button
          color="primary" 
          size="small" 
          variant="contained" 
          onClick={btnSave}
          startIcon={<Icon className="fas fa-save"/>}
        >
          Guardar
        </Button>}
        {formState !== 'search' && <LinkContainer to={{ pathname: '/seguridad/usuario', search: location.search, hash: location.hash }}>
          <Button
            color="primary" 
            size="small" 
            variant="contained"
            startIcon={<Icon className="fas fa-times-circle"/>}
          >
            Cancelar
          </Button>
        </LinkContainer>}
        <Button 
          color="primary" 
          size="small" 
          variant="contained" 
          onClick={onBtnClose} 
          startIcon={<Icon className="fas fa-door-open"/>}
        >
          Salir
        </Button>
      </Footer>
    </Page>
  );
}

const mapStateToProps = state => ({
  formState: state.user.formState,
  loading: state.user.loading,
  usuarioId: state.user.entity.usuarioId
});

export default connect(
  mapStateToProps,
  dispatch => bindActionCreators({...actionCreators}, dispatch)
)(User);