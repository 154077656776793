import React, { useEffect }  from 'react';
import clsx from 'clsx';
import { LinkContainer } from 'react-router-bootstrap';

import CircularProgress from '@material-ui/core/CircularProgress';
import Divider   from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/notify/actions';

const useStyles = makeStyles((theme) => ({
    arrowIcon: {
        fontSize: '0.6rem', 
        marginLeft: '0.2rem',
        overflow: 'unset',
        '@media (min-width: 1401px)': {
            fontSize: '0.75rem', 
            marginLeft: '0.25rem'
        }
    },
    bellIcon: {
        color: '#d2d2d2', 
        fontSize: '5rem', 
        height: '5rem',
        paddingBottom: '1.2rem',
        paddingTop: '3.6rem',
        width: '5rem',
        '@media (min-width: 1401px)': {
            fontSize: '6.25rem', 
            height: '6.25rem',
            paddingBottom: '1.5rem',
            paddingTop: '4.5rem',
            width: '6.25rem',
        }
    },
    content: {
        flex: '1 1 auto',
        overflow: 'auto'
    },
    contentNoData: {
        alignItems: 'center',
        display: 'flex', 
        flexDirection: 'column',  
        fontSize: '0.7rem',
        justifyContent: 'center',  
        lineHeight: '1rem',
        '@media (min-width: 1401px)': {
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
        }
    },
    itemContent: {
        color: '#404040',
        display: '-webkit-box',
        fontSize: '0.6rem',
        fontWeight: 300,
        lineHeight: '0.9rem',
        maxHeight: '3.6rem',
        overflow: 'hidden',
        paddingTop: '0.4rem',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        '@media (min-width: 1401px)': {
            fontSize: '0.75rem',
            lineHeight: '1.125rem',
            maxHeight: '4.5rem',
            paddingTop: '0.5rem'
        }
    },
    itemClose: {
        cursor: 'pointer',
        fontSize: '0.8rem',
        opacity: .4,
        width: 'auto',
        '&:hover': {
            opacity: 1
        },
        '@media (min-width: 1401px)': {
            fontSize: '1rem'
        }
    },
    itemFooter: {
        display: 'flex',
        fontSize: '0.6rem',
        fontWeight: 300,
        lineHeight: '1rem',
        paddingTop: '0.4rem',
        '@media (min-width: 1401px)': {
            fontSize: '0.75rem',
            lineHeight: '1.125rem',
            paddingTop: '0.5rem'
        }
    },
    itemHeader: {
        display: 'flex',
        fontSize: '0.7rem',
        fontWeight: 300,
        lineHeight: '1rem',
        paddingBottom: '0.125rem',
        '@media (min-width: 1401px)': {
            fontSize: '0.875rem',
            lineHeight: '1.125rem'
        }
    },
    itemIcon: {
        fontSize: '0.9rem',
        marginRight: '0.4rem',
        width: 'inherit',
        '@media (min-width: 1401px)': {
            fontSize: '1.125rem',
            marginRight: '0.5rem',
        }
    },
    itemNew: {
        backgroundColor: theme.palette.primary.main + '1a'
    },
    itemWrap: {
        boxSizing: 'border-box',
        padding: '0.4rem 0.4rem',
        position: 'relative',
        '@media (min-width: 1401px)': {
            padding: '0.5rem 0.5rem'
        }
    },
    link: {
        color: '#0078d4',
        cursor: 'pointer',
        fontSize: '0.7rem',
        marginBottom: '0.5rem',
        width: 'fit-content',
        '&:hover': {
            textDecoration: 'underline'
        },
        '@media (min-width: 1401px)': {
            fontSize: '0.875rem',
            marginBottom: '0.4rem'
        }
    },
    loader: {
        left:'calc(50% - 20px)',
        position: 'absolute',
        top:'calc(50% - 20px)'
    },
    panelIn: {
        borderRadius: 0,
        boxShadow: '0 8px 8px 0 rgba(0,0,0,.16)',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 2.2rem)',
        minWidth: '20rem',
        paddingLeft: '1.2rem',
        paddingRight: '1.2rem',
        position: 'absolute',
        right: 0,
        top: '2.25rem',
        width: '20rem',
        zIndex: 1201,
        '@media (min-width: 1401px)': {
            height: 'calc(100% - 2.75rem)',
            minWidth: '27rem',
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
            top: '2.75rem',
            width: '27rem'
        }
    },
    title: {
        fontSize: '0.8rem',
        fontWeight: 600,
        marginBottom: '1rem',
        marginTop: '1rem',
        '@media (min-width: 1401px)': {
            fontSize: '1rem',
            marginBottom: '1.25rem',
            marginTop: '1.25rem'
        }
    }
}));

const Notify = ({ isLoad, notifications, getNotifications, removeNotification, updateRead }) => {
    const classes = useStyles();

    useEffect(() => {
        getNotifications();

        return () => {
            updateRead();
        };
    // eslint-disable-next-line
    }, []);

    function getColorAlert(tipo) {
        switch(tipo){
            case 'danger':
                return '#f00';
            case 'success':
                return '#15c153';
            case 'warning':
                return 'yellow';
            default:
                return '#7dccfc';
        }
    }

    function link(enlace) {
        let url = new URL(`${window.location.origin}${enlace}`);
        let newParams = new URLSearchParams(url.search);
        let oldParams = new URLSearchParams(window.location.search);

        if(oldParams.get('company_code')) {
            newParams.set('company_code', oldParams.get('company_code'));
        }
        
        if(oldParams.get('authuser')) {
            newParams.set('authuser', oldParams.get('authuser'));
        }

        return { pathname: url.pathname, search: newParams.toString(), hash: url.hash };
    }
    
    return (
        <Paper className={classes.panelIn}>
            <div className={classes.title}>Notificaciones</div>
            {!isLoad && (<div className={classes.loader}>
                <CircularProgress color="primary"/>
            </div>)}
            {isLoad && notifications.length === 0 && (<div className={classes.contentNoData}>
                <Icon className={clsx(classes.bellIcon, "fas fa-bell")}/>
                <span>No tiene notificaciones nuevas</span>
                <LinkContainer className={classes.link} to="/">
                    <div>
                        <span>Ver todas las notificaciones</span>
                    </div>
                </LinkContainer>
            </div>)}
            {isLoad && notifications.length > 0 && (<div className={classes.content}>
                <LinkContainer className={classes.link} to="/">
                    <div>
                        <span>Ver todas las notificaciones</span>
                        <Icon className={clsx(classes.arrowIcon, "fas fa-arrow-right")}/>
                    </div>
                </LinkContainer>
                <Divider />
                {notifications.map((item, key) => (<div key={key}>
                    <div className={clsx(classes.itemWrap, { [classes.itemNew]: !item.notificado })}>
                        <div className={classes.itemHeader}>
                            <Icon className={clsx(classes.itemIcon, item.iconoCls)} style={{ color: getColorAlert(item.dTipoAlerta) }}/>
                            <div style={{ flexGrow: 1 }}>
                                {item.enlace !== null && item.enlaceExterno && (<Link className={classes.link} href={item.enlace} rel="noopener" target="_blank">
                                    <span>{item.titulo}</span>
                                    <Icon className={clsx(classes.arrowIcon, "fas fa-external-link-alt")}/>
                                </Link>)}
                                {item.enlace !== null && !item.enlaceExterno && (<LinkContainer className={classes.link} to={link(item.enlace)}>
                                    <span>{item.titulo}</span>
                                </LinkContainer>)}
                                {item.enlace === null && (<span>{item.titulo}</span>)}
                            </div>
                            {!item.permanente && (<Icon className={clsx(classes.itemClose, "fas fa-times")} onClick={() => removeNotification(item.notificacionId)}/>)}
                        </div>
                        <div className={classes.itemContent}>
                            {item.contenido}
                        </div>
                        <div className={classes.itemFooter}>
                            <div style={{ flexGrow: 1}}>{new Date(item.fecha).toLocaleDateString('en-GB') + ' ' + new Date(item.fecha).toLocaleTimeString('en-GB').slice(0,5)}</div>
                            <div>{item.emisor}</div>
                        </div>
                    </div>
                    <Divider />
                </div>
                ))}
            </div>)}
        </Paper>
    )
}

const mapStateToProps = state => ({
    isLoad: state.notify.isLoad,
    notifications: state.notify.notifications,
    total: state.notify.total
});
  
export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Notify);