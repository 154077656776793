import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import { mainReducer } from './main/reducers';
import { notifyReducer } from './notify/reducers';
import { permissionsReducer } from './permissions/reducers';
import { profileReducer } from './profile/reducers';
import { sessionReducer } from './session/reducers';
import { userReducer } from './user/reducers';

function createReducer(asyncReducers, history) {
  return combineReducers({
    main: mainReducer,
    notify: notifyReducer,
    permissions: permissionsReducer,
    profile: profileReducer,
    session: sessionReducer,
    user: userReducer,
    router: connectRouter(history),
    ...asyncReducers
  });
}

export default function configureStore (history, initialState) {

  const middleware = [
    thunk,
    routerMiddleware(history)
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const store = createStore(
    createReducer(undefined, history),
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );

  store.asyncReducers = {};

  return store;
}

export function injectAsyncReducers(store, asyncReducers, history) {
  store.replaceReducer(createReducer(asyncReducers, history));
}