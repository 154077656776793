import React, { useEffect, useState } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../store/main/actions';

const Sencha = ({ color, hash, viewType, addOptionNav, closeOptionSencha }) => {
    const [loaded, setLoaded] = useState(false);
    const [chooser, setChooser] = useState(false);

    useEffect(() => {
        iniciar();
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        chooserTheme();
        // eslint-disable-next-line
    }, [color]);

    useEffect(() => {
        let params = new URLSearchParams(window.location.hash.replace('#','?').replace('|','&'));

        if(params.get('close')) {
            closeOptionSencha();
        }
        else {
            if(params.get('view') && params.get('view') !== viewType) {
                addOptionNav(params.get('view'), params.get('view'), true);
            }
        }
    // eslint-disable-next-line
    }, [hash]);

    async function iniciar() {
        await loadScript('/js/extjs/ext-all.js', 'sigeco-ext-all');
        await loadScript('/js/Extensions/exporter.js', 'sigeco-exporter');
        await loadScript('/js/Extensions/locale/locale-es.js', 'sigeco-locale-es');
        await loadScript('/js/app.js', 'sigeco-app');
        setLoaded(true);
    }

    async function chooserTheme() {
        await loadStyle(`/css/themes/theme-sigeco/theme-sigeco-${color}-all_1.css`, 'sigeco-theme1');
        await loadStyle(`/css/themes/theme-sigeco/theme-sigeco-${color}-all_2.css`, 'sigeco-theme2');
        await loadStyle('/css/sigeco.css', 'sigeco-override');
        setChooser(true);
    }

    function loadScript(source, id) {
        return new Promise((resolve) => {
            let oldScript = document.getElementById(id);

            if(oldScript) {
                if(oldScript.src !== source) {
                    oldScript.src = source;
                    resolve();    
                }
            }
            else {
                let script = document.createElement("script");
                script.id = id;
                script.async = true;
                script.type = "text/javascript";
                script.onload = () => resolve();
                script.src = source;
                document.getElementsByTagName("head")[0].appendChild(script);
            }
        });
    }

    function loadStyle(source, id) {
        return new Promise((resolve) => {
            let oldStyle = document.getElementById(id);

            if(oldStyle) {
                if(oldStyle.href !== source) {
                    oldStyle.href = source;
                    resolve();
                }
            }
            else {
                let style = document.createElement("link");
                style.id = id;
                style.type = 'text/css';
                style.rel = 'stylesheet';
                style.onload = () => resolve();
                style.href = source;
                document.getElementsByTagName('head')[0].appendChild(style);
            }
        });
    }

    if(!loaded || !chooser){
        return (<div>Cargando...</div>)
    }

    return (
        <div id="content-app" />
    );
}

const mapStateToProps = state => ({
    color: state.main.color,
    hash: state.router.location.hash,
    viewType: state.main.viewType
});

export default connect(
  mapStateToProps,
  dispatch => bindActionCreators(actionCreators, dispatch)
)(Sencha);