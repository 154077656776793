import {
    ADD_OPTION_NAV,
    ADVANCED_SEARCH_CLOSE,
    ADVANCED_SEARCH_OPEN,
    CHANGE_THEME, 
    LOAD_FINISHED,
    MESSAGE_BOX_CLOSE,
    MESSAGE_BOX_OPEN,
    RECEIVE_MODULES_LIST, 
    RECEIVE_OPTIONS_LIST,
    RECEIVE_RESOURCES_LIST,
    RECEIVE_ROUTES_LIST,
    REMOVE_OPTION_NAV,
    REQUEST_OPTIONS_LIST, 
    SET_MODULE,
    SET_OPTION,
    UPDATE_OPTION_NAV
}  from './types';

const initialState = {
    color: 'orange',
    advancedSearch: {
        autoLoad: false,
        data: [],
        fields: [],
        multiSelect: false,
        open: false,
        paginated: false,
        remote: false,
        title: ''
    },
    dialog: {
        button: 'ok',
        icon: 'info',
        message: '',
        open: false
    },
    load: false,
    loadOptions: false,
    moduloId: -1,
    modulos: [],
    navs: [],
    opciones: [],
    remoteRoutes: [],
    resources: {},
    totalLoad: 0,
    viewType: ''
};

export const mainReducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case ADD_OPTION_NAV:
            return {
                ...state,
                navs: [...state.navs, action.option]
            };
        case ADVANCED_SEARCH_CLOSE:
            return {
                ...state,
                advancedSearch: {
                    ...state.advancedSearch,
                    open: false
                }
            };
        case ADVANCED_SEARCH_OPEN:
            return {
                ...state,
                advancedSearch: {
                    ...state.advancedSearch,
                    autoLoad: action.payload.autoLoad || false,
                    data: action.payload.data || initialState.advancedSearch.data,
                    fields: action.payload.fields || initialState.advancedSearch.fields,
                    multiSelect: action.payload.multiSelect || initialState.advancedSearch.multiSelect,
                    open: true,
                    paginated: action.payload.paginated || initialState.advancedSearch.paginated,
                    remote: action.payload.remote || false,
                    title: action.payload.title || initialState.advancedSearch.title,
                    callback: action.payload.callback,
                    getData: action.payload.getData
                }
            }
        case CHANGE_THEME:
            return {
                ...state,
                color: action.color
            };
        case LOAD_FINISHED:
            return {
                ...state,
                totalLoad: state.totalLoad + 1
            };
        case MESSAGE_BOX_CLOSE:
            return {
                ...state,
                dialog: {
                    ...state.dialog,
                    open: false
                }
            };
        case MESSAGE_BOX_OPEN:
            return {
                ...state,
                dialog: {
                    ...initialState.dialog,
                    button: action.payload.button,
                    icon: action.payload.icon,
                    message: action.payload.message,
                    open: true,
                    callback: action.payload.callback
                }
            };
        case RECEIVE_MODULES_LIST:
            return {
                ...state,
                modulos: action.modulos
            };
        case RECEIVE_OPTIONS_LIST:
            return {
                ...state,
                loadOptions: false,
                opciones: action.opciones
            };
        case RECEIVE_RESOURCES_LIST:
            return {
                ...state,
                resources: action.payload
            }
        case RECEIVE_ROUTES_LIST:
            return {
                ...state,
                remoteRoutes: action.remoteRoutes
            };
        case REMOVE_OPTION_NAV:
            return {
                ...state,
                navs: state.navs.filter(f => f.viewType !== action.viewType)
            };
        case REQUEST_OPTIONS_LIST:
            return {
                ...state,
                loadOptions: true,
                opciones: []
            };
        case SET_MODULE:
            return {
                ...state,
                moduloId: action.moduloId
            };
        case SET_OPTION:
            return {
                ...state,
                viewType: action.viewType
            };
        case UPDATE_OPTION_NAV:
            return {
                ...state,
                navs: [
                    ...state.navs.map((option) => {
                        if(option.viewType === action.option.viewType) {
                            option.path = action.option.path;
                            option.favorite = action.option.favorite;
                        }
                        return option;
                    })
                ]
            };
        default:
            return state;
    }

};