import React from 'react';
import clsx from 'clsx';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/user/actions';

const useStyles = makeStyles((theme) => ({
    alternate: {
        //backgroundColor: theme.palette.primary.light
    },
    cell: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis', 
        overflow: 'hidden',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
    cellHeader: {
        backgroundImage: '-webkit-linear-gradient(top, #fff, #fff 30%, #fff 65%, #f0f0f0)',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis', 
        overflow: 'hidden',
        lineHeight:'1rem'
    },
    cellNumber: {
        backgroundImage: '-webkit-linear-gradient(top, #fff, #fff 30%, #fff 65%, #f0f0f0)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis', 
        overflow: 'hidden',
        padding: 6,
        textAlign: 'center',
        maxWidth: 25
    },
    details: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    },
    icon: {
        color: '#3C4858',
        fontSize: '0.875rem',
        paddingRight: 6
    },
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    },
    row: {
        color: "#404040"
    },
    title: {
        display: 'flex',
        fontSize: '0.875rem',
        minHeight: '2rem',
        padding: '0 1rem 0 1rem'
    },
    titleContent: {
        alignItems: 'center',
        display: 'flex',
        margin: '0.5rem 0'
    }
}));

const columns = [
    { name: 'dAlerta', title: 'Título', width: 200 },
    { name: 'contenido', title: 'Contenido', width: 400 },
    { name: 'emisor', title: 'Emisor', width: 135 },
    { name: 'fecha', title: 'Fecha', width: 100 },
    { name: 'notificado', title: 'Notificado', width: 115 },
    { name: 'fechaNotificado', title: 'Fecha Notificación', width: 150 }
];

const data = [
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: true, fechaNotificado: '11/10/2019 17:05' },
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: true, fechaNotificado: '11/10/2019 17:05' },
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: false, fechaNotificado: '11/10/2019 17:05' },
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: true, fechaNotificado: '11/10/2019 17:05' },
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto, Se ha generado el documento tanto, ', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: true, fechaNotificado: '11/10/2019 17:05' },
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: true, fechaNotificado: '11/10/2019 17:05' },
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: true, fechaNotificado: '11/10/2019 17:05' },
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: true, fechaNotificado: '11/10/2019 17:05' },
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: true, fechaNotificado: '11/10/2019 17:05' },
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: true, fechaNotificado: '11/10/2019 17:05' },
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: true, fechaNotificado: '11/10/2019 17:05' },
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: true, fechaNotificado: '11/10/2019 17:05' },
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: true, fechaNotificado: '11/10/2019 17:05' },
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: true, fechaNotificado: '11/10/2019 17:05' },
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: true, fechaNotificado: '11/10/2019 17:05' },
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: true, fechaNotificado: '11/10/2019 17:05' },
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: true, fechaNotificado: '11/10/2019 17:05' },
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: true, fechaNotificado: '11/10/2019 17:05' },
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: true, fechaNotificado: '11/10/2019 17:05' },
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: true, fechaNotificado: '11/10/2019 17:05' },
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: true, fechaNotificado: '11/10/2019 17:05' },
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: true, fechaNotificado: '11/10/2019 17:05' },
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: true, fechaNotificado: '11/10/2019 17:05' },
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: true, fechaNotificado: '11/10/2019 17:05' },
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: true, fechaNotificado: '11/10/2019 17:05' },
    { dAlerta: 'Alerta 1', contenido: 'Se ha generado el documento tanto', emisor: 'jabanto', fecha: '10/10/2019 20:45', notificado: true, fechaNotificado: '11/10/2019 17:05' }
];

const NotificationList = () => {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <div className={classes.title}>
                <div className={classes.titleContent}>
                    <Icon className={clsx("fas fa-th-list", classes.icon)}/>
                    <div>Resultados</div>
                </div>
            </div>
            <div className={classes.details}>
                {/* <div style={{ flex: '1 1 auto', height: 0 }}>
                    <Table size="small" style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
                        <TableHead style={{ flex: '0 0 auto', boxSizing: 'border-box', overflowX: 'hidden' }}>
                            <div style={{ boxSizing: 'border-box', display: 'flex', width: columns.map(item => item.width).reduce((prev, next) => prev + next) }}>
                                <TableRow style={{ display: 'flex' }}>
                                    <TableCell>#</TableCell>
                                    {columns.map((column) => (
                                        <TableCell style={{ boxSizing: 'border-box', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width: column.width}}>
                                            {column.title}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </div>
                        </TableHead>
                        <TableBody style={{ flex: '1 1 auto', height: '100%', overflowX: 'auto', overflowY: 'auto' }}>
                            <div style={{ width: columns.map(item => item.width).reduce((prev, next) => prev + next) }}>
                            {data.map((row, key) => (
                                <TableRow>
                                    <TableCell>{key + 1}</TableCell>
                                    {columns.map((column) => (
                                        <TableCell style={{ width: column.width }} className={classes.cell}>{row[column.name]}</TableCell>
                                    ))}
                                </TableRow> 
                            ))}
                            </div>
                        </TableBody>
                    </Table>
                </div> */}
                <div style={{ flex: '1 1 auto', height: 0, overflow: 'auto', minHeight: 175 }}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell className={clsx(classes.cellHeader, classes.cellNumber)}>#</TableCell>
                            <TableCell className={classes.cellHeader} padding="checkbox">
                                <Checkbox
                                color="primary"
                                    inputProps={{ 'aria-label': 'select all desserts' }}
                                />
                            </TableCell>
                            {columns.map((column) => (
                                <TableCell className={classes.cellHeader}>
                                    {column.title}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, key) => (
                            <TableRow hover className={clsx(classes.row, {[classes.alternate]: key%2 !== 0})}>
                                <TableCell className={classes.cellNumber}>{key + 1}</TableCell>
                                <TableCell className={classes.cell} padding="checkbox">
                                    <Checkbox
                                    color="primary"
                                    inputProps={{ 'aria-labelledby': row.notificationId }}
                                    />
                                </TableCell>
                                {columns.map((column) => (
                                    <TableCell className={classes.cell}>{row[column.name]}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                </div>
                <TablePagination
                    component="div"
                    count={20}
                    page={1}
                    rowsPerPage={5}
                    rowsPerPageOptions={[5, 20, 50]}
                    labelRowsPerPage="Filas por página"
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                />
            </div>
        </Paper>
    )
}

const mapStateToProps = state => ({
});
  
export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(NotificationList);