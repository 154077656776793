import axios from 'axios';
import {
    ADD_NOTIFICATION,
    RECEIVE_NOTIFICATIONS,
    RECEIVE_POPUPS,
    REMOVE_NOTIFICATION,
    REMOVE_POPUP,
    REQUEST_NOTIFICATIONS,
    SET_TOTAL_NO_READ
} from './types';

export const addNotification = (notificacionId, dTipoAlerta, titulo, contenido, fecha, enlace, enlaceExterno, iconoCls, emisor) => (dispatch, getState) => {
    let notificacion = {
        notificacionId, dTipoAlerta, titulo, contenido, fecha, enlace, enlaceExterno, iconoCls, emisor
    }
    
    dispatch({ type: ADD_NOTIFICATION, payload: notificacion });
    dispatch({ type: SET_TOTAL_NO_READ, payload: getState().notify.total + 1 });
};

export const getNotifications = () => async (dispatch, getState) => {
    try {
        dispatch({ type: REQUEST_NOTIFICATIONS });

        let url = `${getState().main.resources.uriSvcNotifyApi}/api/v1/notificaciones?start=0&pageSize=7`;
        let response = await axios.get(url);
        let data = response.data.data;

        dispatch({ type: RECEIVE_NOTIFICATIONS, payload: data });
    } catch (error) {
        console.log(`Error Lista Notificaciones: ${error}`);
    }
};

export const getPopups = () => async (dispatch, getState) => {
    try {
        let url = `${getState().main.resources.uriSvcNotifyApi}/api/v1/notificaciones?soloPopup=${true}`;
        let response = await axios.get(url);
        let data = response.data.data;

        dispatch({ type: RECEIVE_POPUPS, payload: data });
    } catch (error) {
        console.log(`Error Lista Notificaciones Popup: ${error}`);
    }
};

export const getTotalNoRead = (fnCallback) => async (dispatch, getState) => {
    try {

        let url = `${getState().main.resources.uriSvcNotifyApi}/api/v1/notificaciones/sinnotificar`;
        let response = await axios.get(encodeURI(url));
        let total = response.data;

        if(total > 0) {
            fnCallback('info', null, `Tiene ${total} mensaje(s) nuevo(s).`);
            dispatch({ type: SET_TOTAL_NO_READ, payload: response.data });
        }
    } catch (error) {
        console.log(error);
    }
};

export const removeNotification = (notificacionId) => async (dispatch, getState) => {
    try {
        let notificacion = getState().notify.notifications.filter(item => item.notificacionId === notificacionId);

        if(notificacion.length > 0){
            if(!notificacion[0].notificado) {
                dispatch({ type: SET_TOTAL_NO_READ, payload: getState().notify.total - 1 });
            }
            dispatch({ type: REMOVE_NOTIFICATION, payload: notificacionId });

            let url = `${getState().main.resources.uriSvcNotifyApi}/api/v1/notificaciones/${notificacionId}`;
            await axios.delete(encodeURI(url));
        }
    } catch (error) {
        console.log(error);
    }
};

export const removePopup = (notificacionId) => ({ type: REMOVE_POPUP, payload: notificacionId });

export const updateRead = () => async (dispatch, getState) => {
    if(getState().notify.notifications.length === 0) {
        return;
    }

    try {
        let data = [...getState().notify.notifications];
        let total = getState().notify.total;
        let counter = data.map(item => item.notificado || item.permanente ? 0 : 1).reduce((prev, next) => prev + next);

        dispatch({ type: SET_TOTAL_NO_READ, payload: total - counter });
    } catch (error) {
        console.log(error);
    }
};