import axios from 'axios';
import { push } from 'connected-react-router';

import { IdentityUrl } from '../../config';
import {
    ADD_OPTION_NAV,
    ADVANCED_SEARCH_CLOSE,
    ADVANCED_SEARCH_OPEN,
    CHANGE_THEME,
    MESSAGE_BOX_CLOSE,
    MESSAGE_BOX_OPEN,
    LOAD_FINISHED, 
    RECEIVE_MODULES_LIST, 
    RECEIVE_OPTIONS_LIST,
    RECEIVE_RESOURCES_LIST,
    RECEIVE_ROUTES_LIST,
    REMOVE_OPTION_NAV,
    REQUEST_OPTIONS_LIST, 
    SET_MODULE,
    SET_OPTION,
    UPDATE_OPTION_NAV
}  from './types';

export const addFavorite = (optionId, viewType, path) => async (dispatch) => {
    try {
        let url = `${IdentityUrl}/api/v1/modulos/favoritos?optionId=${optionId}`;

        await axios.post(url, null, {headers: {'Content-Type': 'application/json'}});
        dispatch(updateOptionNav(viewType, path, true));
    } catch (error) {
        console.log(error);
    }
}

export const dropFavorite = (optionId, viewType, path) => async (dispatch) => {
    try {
        let url = `${IdentityUrl}/api/v1/modulos/favoritos?optionId=${optionId}`;
        
        await axios.put(url, null, {headers: {'Content-Type': 'application/json'}});

        dispatch(updateOptionNav(viewType, path, false));
    } catch (error) {
        console.log(error);
    }
}

export const addOptionNav = (text, viewType, isSencha, icon, path, access, optionId, favorite) => (dispatch, getState) => {
    let navs = getState().main.navs;
    
    if(!navs.filter(n => n.viewType === viewType)[0]) {
        dispatch({ type: ADD_OPTION_NAV, option: {
            icon: icon,
            name: text,
            reactivo: !isSencha,
            viewType: viewType,
            path: path,
            access: access || [],
            optionId: optionId,
            favorite: favorite || false
        }});
    }

    dispatch({ type: SET_OPTION, viewType });

    if(isSencha) {
        let params = new URLSearchParams(window.location.hash.replace('#','?').replace('|','&'));
        
        params.set('view', viewType);
        window.location.hash = '';
        window.location.hash = params.toString().replace('&', '|');
    }
    dispatch(setTitle());
};

export const updateOptionNav = (viewType, path, favorite) => (dispatch) => {
    dispatch({ type: UPDATE_OPTION_NAV, option: {
        viewType: viewType,
        path: path,
        favorite: favorite }
    });
};

export const closeAdvancedSearch = () => ({ type: ADVANCED_SEARCH_CLOSE });

export const closeMessageBox = () => ({ type: MESSAGE_BOX_CLOSE });

export const closeOption = () => (dispatch, getState) => {
    if(getState().main.viewType === '') {
        return;
    }

    dispatch({ type: REMOVE_OPTION_NAV, viewType: getState().main.viewType });
    dispatch(push(`/${window.location.search}`));
};

export const closeOptionSencha = () => (dispatch, getState) => {
    let params = new URLSearchParams(window.location.hash.replace('#','?').replace('|','&'));
    let viewType = params.get('view');

    if(viewType) {
        let option = getState().main.navs.filter(n => n.viewType === viewType)[0];

        if(option) {
            dispatch({ type: REMOVE_OPTION_NAV, viewType: option.viewType });
            dispatch(push(`/${getState().router.location.search}`));
        }
    }
};

export const getModules = () => async (dispatch) => {
    try {
        let url = `${IdentityUrl}/api/v1/modulos`;
        let response = await axios.get(url);
        let data = response.data;

        let recursos = {};

        data.recursos.forEach(recurso => {
            recursos = {
                ...recursos,
                [recurso.nombreHost]: recurso.uriHost
            }
        });

        dispatch({ type: RECEIVE_MODULES_LIST, modulos: data.modulos });
        dispatch({ type: RECEIVE_ROUTES_LIST, remoteRoutes: data.rutas });
        dispatch({ type: RECEIVE_RESOURCES_LIST, payload: recursos });
        dispatch({ type: LOAD_FINISHED });
    } catch (error) {
        console.log(`Error Modulos: ${error}`);
    }
};

export const getOptions = () => async (dispatch, getState) => {
    try {
        dispatch({ type: REQUEST_OPTIONS_LIST });

        let moduloId = getState().main.moduloId;
        let url = `${IdentityUrl}/api/v1/modulos/${moduloId}/opciones/arbol`;
        let response = await axios.get(url);
        let data = response.data;

        dispatch({ type: RECEIVE_OPTIONS_LIST, opciones: data });
    } catch (error) {
        console.log(`Error Opciones: ${error}`);
    }
};

export const openAdvancedSearch = (opts) => ({ type: ADVANCED_SEARCH_OPEN, payload: opts });

export const openMessageBox = (opts) => ({ type: MESSAGE_BOX_OPEN, payload: opts });

export const setModule = () => (dispatch, getState) => {
    let params = new URLSearchParams(getState().router.location.search);
    let moduloId = params.get('moduloId') || 0;

    if(moduloId === getState().main.moduloId) {
        return;
    }

    dispatch({ type: SET_MODULE, moduloId });
    dispatch(setTitle());
    dispatch(getOptions());
};

export const setOption = (viewType) => (dispatch) => {
    dispatch({ type: SET_OPTION, viewType });
    dispatch(setTitle());
};

export const setTitle = () => (dispatch, getState) => {
    let dModule = '', dView = '';
    let modulos = getState().main.modulos.filter(m =>  m.moduloId === parseInt(getState().main.moduloId));
    let vistas = getState().main.navs.filter(m => m.viewType === getState().main.viewType);

    if(vistas.length > 0) {
      dView = vistas[0].name + ' - ';
    }

    if(modulos.length > 0) {
      dModule = modulos[0].nombreCorto + ' - ';
    }

    document.title = `${dView}${dModule}Sigeco`;
};

export const themeChange = (color) => (dispatch) => {
    if(localStorage) {
        localStorage.setItem('sigecoColor', color);
    }

    dispatch({ type: CHANGE_THEME, color });
};