import React from 'react';

import { FieldSet } from '@sigeco/tools';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/user/actions';

const useStyles = makeStyles(() => ({
    content: {
        padding: '0.5rem 0.75rem 0.75rem'
    },
    root: {
        flex: '0 0 auto',
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem',
        }
    }
}));

const UserFilter = ({ filters, onUpdateFieldFilter }) => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:701px)');

    function onFieldChange(event) {
        onUpdateFieldFilter(event.target.name, event.target.value);
    }

    return (
        <div className={classes.root}>
            <FieldSet  iconCls="fas fa-filter" title="Filtros" defaultExpanded>
                <Grid className={classes.content} container spacing={2}>
                    <Grid item xs>
                        <TextField
                            autoComplete="off"
                            fullWidth
                            label="Nombres o usuario"
                            name="nombre"
                            value={filters.nombre}
                            variant={matches ? "outlined" : "standard"}
                            onChange={onFieldChange}
                        />
                    </Grid>
                </Grid>
            </FieldSet>
        </div>
    )
}

const mapStateToProps = state => ({
    filters: state.user.filters
});
  
export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(UserFilter);