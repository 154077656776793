import React from 'react';
import { ReactComponent as Admin } from "./modules/admin.svg";
import { ReactComponent as Budget } from "./modules/budget.svg";
import { ReactComponent as Logistics } from "./modules/logistics.svg";
import { ReactComponent as Maintenance } from "./modules/maintenance.svg";
import { ReactComponent as Operations } from "./modules/operations.svg";
import { ReactComponent as Payroll } from "./modules/payroll.svg";
import { ReactComponent as Sales } from "./modules/sales.svg";
import { ReactComponent as Security } from "./modules/security.svg";

const iconTypes = {
  admin: Admin,
  budget: Budget,
  logistics: Logistics,
  maintenance: Maintenance,
  operations: Operations,
  payroll: Payroll,
  sales: Sales,
  security: Security
}

const IconComponent = ({ className, name, ...props }) => {
  const IconSigeco = iconTypes[name];

  return <IconSigeco className={className} {...props} />;
}

export default IconComponent;