import React from 'react';

import { FieldSet } from '@sigeco/tools';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/permissions/actions';

const useStyles = makeStyles(() => ({
    content: {
        padding: '0.5rem 0.75rem 0.75rem'
    },
    root: {
        flex: '0 0 auto',
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem',
        }
    }
}));

const PermissionsFilter = ({ errors, filters, onSearchCompanyClick, onSearchModuleClick, onSearchUserClick }) => {
    const classes = useStyles();
    const big = useMediaQuery('(min-width:1401px)');
    const middle = useMediaQuery('(min-width:701px)');

    return (
        <div className={classes.root}>
            <FieldSet  iconCls="fas fa-filter" title="Filtros" defaultExpanded expandable>
                <Grid className={classes.content} container spacing={big || middle ? 2 : 1}>
                    <Grid item xs={12} container>
                        <Grid item xs={big ? 4 : (middle ? 6 : 12)}>
                            <TextField
                                autoComplete="off"
                                error={errors.nombreUsuario !== ''}
                                fullWidth
                                helperText={errors.nombreUsuario}
                                label="Usuario"
                                placeholder="Click para buscar usuario"
                                value={filters.nombreUsuario}
                                variant={big || middle ? "outlined" : "standard"}
                                onClick={() => onSearchUserClick()}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton 
                                                className="fas fa-external-link-alt"
                                                style={{ fontSize: '0.8125rem' }}
                                            />
                                        </InputAdornment>
                                    ),
                                    readOnly: true
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container>
                        <Grid item xs={big ? 4 : (middle ? 6 : 12)}>
                            <TextField
                                autoComplete="off"
                                error={errors.dEmpresa !== ''}
                                fullWidth
                                helperText={errors.dEmpresa}
                                label="Empresa"
                                placeholder="Click para buscar empresa"
                                value={filters.dEmpresa}
                                variant={big || middle ? "outlined" : "standard"}
                                onClick={() => onSearchCompanyClick()}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton 
                                                className="fas fa-external-link-alt"
                                                style={{ fontSize: '0.8125rem' }}
                                            />
                                        </InputAdornment>
                                    ),
                                    readOnly: true
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container>
                        <Grid item xs={big ? 4 : (middle ? 6 : 12)}>
                            <TextField
                                autoComplete="off"
                                error={errors.dModulo !== ''}
                                fullWidth
                                helperText={errors.dModulo}
                                label="Módulo"
                                placeholder="Click para buscar módulo"
                                value={filters.dModulo}
                                variant={big || middle ? "outlined" : "standard"}
                                onClick={() => onSearchModuleClick()}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton 
                                                className="fas fa-external-link-alt"
                                                style={{ fontSize: '0.8125rem' }}
                                            />
                                        </InputAdornment>
                                    ),
                                    readOnly: true
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </FieldSet>
        </div>
    );

};

const mapStateToProps = state => ({
    errors: state.permissions.errors,
    filters: state.permissions.filters
});
  
export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(PermissionsFilter);
