export const generateRandomCode = (() => {
    const UPPER_CHARACTERS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    const LOWER_CHARACTERS = "abcdefghijklmnopqrstuvwxyz".split("");
    const NUMERIC_CHARACTERS = "1234567890".split("");
    const NO_CHARACTERS = "$%&".split("");

    return length => {

      return  (UPPER_CHARACTERS[Math.floor(Math.random() * UPPER_CHARACTERS.length)]
        + new Array(length - 4).fill(null).map(() => {
          return LOWER_CHARACTERS[Math.floor(Math.random() * LOWER_CHARACTERS.length)];
        }).join("")
        + new Array(2).fill(null).map(() => {
          return NUMERIC_CHARACTERS[Math.floor(Math.random() * NUMERIC_CHARACTERS.length)];
        }).join(""))
        + NO_CHARACTERS[Math.floor(Math.random() * NO_CHARACTERS.length)];
    }
  })();
