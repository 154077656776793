import React, { useEffect } from 'react';
import { useRouteMatch } from "react-router-dom";

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Header from '../components/main/Header';
import NotFound from './NotFound';
import ProfileDetails from '../components/profile/ProfileDetails';
import ProfileSummary from '../components/profile/ProfileSummary';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../store/profile/actions';
import { addOptionNav } from '../store/main/actions';

const useStyles = makeStyles((theme) => ({
    body: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        padding: '0 0.5rem 0.5rem',
        overflowY: 'auto',
        '@media (min-width: 701px)': {
          padding: '0 0.75rem 0.75rem'
        },
        '@media (min-width: 1401px)': {
          padding: '0 1rem 1rem'
        }
    },
    root: {
        backgroundColor: theme.palette.primary.main + '14', 
        boxSizing: 'border-box',
        flexDirection: 'column',
        display: 'flex',
        height: '100%',
        position: 'relative'
    }
}));

const Profile = ({ loading, nombre, notFound, addOptionNav, getProfile }) => {
    const classes = useStyles();
    const match = useRouteMatch();

    useEffect(() => {
        addOptionNav('Perfil', '/perfil', false, '', '/perfil');
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getProfile(match.params['id']);
      // eslint-disable-next-line
    }, [match.path]);

    if(notFound) {
        return (
            <NotFound />
        )
    }

    return (
        <div className={classes.root}>
            <Header iconCls="fas fa-id-badge" subtitle="Perfil" title={nombre} />
            <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={loading} transitionDuration={0}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className={classes.body}>
                <ProfileSummary />
                <ProfileDetails />
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    loading: state.profile.loading,
    nombre: state.profile.entity.nombre,
    notFound: state.profile.notFound,
});
  
export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({...actionCreators, addOptionNav}, dispatch)
)(Profile);