import React, { useState } from 'react';
import clsx from 'clsx';

import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Fab from '@material-ui/core/Fab';
import { FieldSet } from '@sigeco/tools';
import Icon from '@material-ui/core/Icon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/user/actions';

const useStyles = makeStyles(() => ({
    chip: {
        margin: '0.5rem 0.5rem 0 0'
    },
    content: {
        padding: '0.5rem 0.75rem 0.75rem'
    },
    icon: {
        fontSize: '1rem'
    },
    root: {
        flex: '0 0 auto',
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem',
        }
    }
}));

const UserCompany = ({ empresas, formState, onDropCompany, onSearchCompanyClick, onSelectPrincipal }) => {
    const classes = useStyles();
    const [state, setState] = useState({});

    function handleClick(event, id) {
        setState(prevState => ({ ...prevState, [id]: event.currentTarget }));
    }

    function handleClose(id) {
        setState(prevState => ({ ...prevState, [id]: null }));
    }

    function handleDelete(empresaId) {
        onDropCompany(empresaId);
    }

    function handleSelect(item) {
        onSelectPrincipal(item);
        handleClose(item.empresaId);
    }

    return (
        <div className={classes.root}>
            <FieldSet  iconCls="fas fa-building" title="Empresas Asignadas">
                <div className={classes.content}>
                    {(formState === 'edit' || formState === 'new') && (<div>
                        <Fab aria-label="agregar" color="primary" onClick={() => onSearchCompanyClick()} size="small">
                            <Icon className={clsx("fas fa-plus", classes.icon)} style={{ fontSize: '0.875rem'}}/>
                        </Fab>
                    </div>)}
                    {empresas.map((item, index) => {
                        return (
                            <div key={index} style={{ display: 'inline-flex' }}>
                                <Chip
                                    avatar={item.principal ? <Avatar>P</Avatar> : undefined}
                                    className={classes.chip}
                                    clickable={!item.principal && (formState === 'edit' || formState === 'new')}
                                    color={item.principal ? "primary" : "default"}
                                    deleteIcon={<Icon className="fas fa-times-circle" />}
                                    label={item.nombre}
                                    onClick={!item.principal && (formState === 'edit' || formState === 'new') ? event => handleClick(event, item.empresaId) : null}
                                    onDelete={ (formState === 'edit' || formState === 'new') ? () => handleDelete(item.empresaId) : null}
                                    variant="outlined" 
                                />
                                {!item.principal && (<Menu
                                    id="simple-menu"
                                    anchorEl={state[item.empresaId]}
                                    keepMounted
                                    open={Boolean(state[item.empresaId])}
                                    onClose={() => handleClose(item.empresaId)}
                                >
                                    <MenuItem onClick={() => handleSelect(item)}>Seleccionar Principal</MenuItem>
                                </Menu>)}
                            </div>
                        )
                    })}
                </div>
            </FieldSet>
        </div>
    );

};

const mapStateToProps = state => ({
    empresas: state.user.entity.empresas,
    formState: state.user.formState
});
  
export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(UserCompany);