import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import format from "date-fns/format";
import isBefore from "date-fns/isBefore";
import isValid from "date-fns/isValid";

import { AutoSizer, Grid, ScrollSync } from 'react-virtualized';
import { FieldSet } from '@sigeco/tools';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Draggable from "react-draggable";
import Icon from '@material-ui/core/Icon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableCell from '@material-ui/core/TableCell';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/permissions/actions';

const { KeyboardDatePicker } = window['@material-ui/pickers'];

const useStyles = makeStyles((theme) => ({
    cell: {
        alignItems: 'center',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        boxSizing: 'border-box',
        display: 'flex',
        overflow: 'hidden',
        padding: '0 5px 0 6px',
        textOverflow: 'ellipsis',
        userSelect: 'none',
        whiteSpace: 'nowrap'
    },
    center: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    checkbox:{
      padding: 0
    },
    dragHandle: {
        flex: '0 0 16px',
        zIndex: 2,
        cursor: 'col-resize',
        color: '#0085ff',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)'
        }
    },
    dragHandleActive: {
      color: '#0b6fcc',
      zIndex: 3,
      '&:hover': {
        color: '#0b6fcc',
        zIndex: 3
      }
    },
    dragHandleIcon: {
      flex: '0 0 3px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    header: {
        backgroundImage: '-webkit-linear-gradient(top, #fff, #fff 30%, #fff 65%, #f0f0f0)',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        overflow: 'hidden !important'
    },
    headerCell: {
        borderBottom: 'none',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        boxSizing: 'border-box',
        display: 'flex',
        padding: '4px 0 4px 6px',
        userSelect: 'none',
        '&:hover': {
          backgroundImage: `-webkit-linear-gradient(top, #fff, #fff 30%, ${theme.palette.primary.light} 65%, ${theme.palette.primary.light})`
        }
    },
    icon: {
        color: '#3C4858',
        fontSize: '0.8125rem',
        height: 'auto',
        paddingRight: 6,
        width: 'auto'
    },
    iconButton: {
        color: '#3C4858',
        fontSize: '0.8125rem',
        paddingRight: 6
    },
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    },
    rowHover: {
        backgroundColor: theme.palette.primary.light
    },
    tableWrap: {
      flex: '1 1 auto'
  },
    textNoData: {
        color: "gray", 
        fontSize: '0.75rem', 
        padding: '0.625rem'
    }
    
}));

const PermissionsList = ({ columns, data, loading, selected, onUpdateColumn, onUpdateDataTable, setSelectedRow }) => {
    const classes = useStyles();
    const headerEl = useRef(null);
    const bodyEl = useRef(null);
    const [rowHoverIndex, setRowHoverIndex] = useState(-1);
    const matches = useMediaQuery('(min-width:701px)');

    useEffect(() => {
      bodyEl.current.recomputeGridSize();
    // eslint-disable-next-line
    }, [data]);


    const resizeRow = ({ column, deltaX }) => {
        if(column.width + deltaX <= 25) {
          return;
        }
    
        column.width += deltaX;
        onUpdateColumn(column);
        headerEl.current.recomputeGridSize();
        bodyEl.current.recomputeGridSize();
    }

    const renderItem = ({ columnIndex, index, key, level, opcion, style } ) => {
        let onClick = event => {
            event.stopPropagation();
            
            onUpdateDataTable({ ...opcion, expanded: !opcion.expanded});
            bodyEl.current.recomputeGridSize();
            bodyEl.current.forceUpdate();
        }
        let top = 0;
        
        switch (columnIndex) {
          case 0:
            if(opcion.expanded) {
              return (
                <div key={key + '-' + columnIndex} style={{ ...style, display: 'flex', flexDirection: 'column' }} >
                  <TableCell className={clsx(classes.cell, { 
                      [classes.rowHover]: key === rowHoverIndex
                    })}
                    style={{ height: 26, width: style.width, alignItems: 'center', display: 'flex' , paddingLeft: `${0.75 + level*2.25}rem` }}
                    onClick={() => setSelectedRow((opcion.detailOptionId === 0 ? opcion.optionId : opcion.detailOptionId))}
                    onMouseEnter={() => setRowHoverIndex(key)}
                    onMouseLeave={() => setRowHoverIndex(-1)}
                  >
                    <Icon 
                      className={clsx('fas fa-caret-down', classes.iconButton)}
                      style={{ cursor: 'pointer' }}
                      onClick={onClick} 
                    />
                    <Icon className={clsx(opcion.detailOptionId > 0 ? 'fas fa-ticket-alt' : opcion.iconCls, classes.icon)}/>
                    {opcion[columns[columnIndex].dataKey]}
                  </TableCell>
                  {opcion.children.map(function(child, index) {
                    if(index === 0) {
                      top = 0;
                    }
                    let newStyle = { ...style, height: getExpandedItemCount(child) * 26, top: top + 26 };
                    top += newStyle.height;

                    return renderItem({
                      columnIndex: columnIndex,
                      index: index,
                      key: key + '-' + index,
                      level: level + 1,
                      opcion: child,
                      style: newStyle
                    });
                  })}
                </div> 
              );
            } else if(opcion.children) {
              return (
                <TableCell className={clsx(classes.cell, { 
                    [classes.rowHover]: key === rowHoverIndex
                  })} key={key + '-' + columnIndex}
                  style={{ ...style, alignItems: 'center', display: 'flex' , paddingLeft: `${0.75 + level*2.25}rem` }}
                  onClick={() => setSelectedRow((opcion.detailOptionId === 0 ? opcion.optionId : opcion.detailOptionId))}
                  onMouseEnter={() => setRowHoverIndex(key)}
                  onMouseLeave={() => setRowHoverIndex(-1)}
                >
                  <Icon 
                    className={clsx('fas fa-caret-right', classes.iconButton)}
                    style={{ cursor: 'pointer' }}
                    onClick={onClick} 
                  />
                  <Icon className={clsx(opcion.detailOptionId > 0 ? 'fas fa-ticket-alt' : opcion.iconCls, classes.icon)}/>
                  {opcion[columns[columnIndex].dataKey]}
                </TableCell>
              );
            } else {
              return (
                <TableCell className={clsx(classes.cell, { 
                    [classes.rowHover]: key === rowHoverIndex
                  })} key={key + '-' + columnIndex}
                  style={{ ...style, alignItems: 'center', display: 'flex', paddingLeft: `${2 + level*2.25}rem` }}
                  onClick={() => setSelectedRow((opcion.detailOptionId === 0 ? opcion.optionId : opcion.detailOptionId))}
                  onMouseEnter={() => setRowHoverIndex(key)}
                  onMouseLeave={() => setRowHoverIndex(-1)}
                >
                  <Icon className={clsx(opcion.detailOptionId > 0 ? 'fas fa-ticket-alt' : opcion.iconCls, classes.icon)}/>
                  {opcion[columns[columnIndex].dataKey]}
                </TableCell>
              );
            } 
          case 1:
            if(opcion.expanded) {
              return (
                <div key={key + '-' + columnIndex} style={{ ...style, left: level === 0 ? style.left : 0, display: 'flex', flexDirection: 'column' }}>
                  <TableCell className={clsx(classes.cell, { 
                      [classes.rowHover]: key === rowHoverIndex
                    })}
                    style={{ height: 26, width: style.width }}
                    onClick={() => setSelectedRow((opcion.detailOptionId === 0 ? opcion.optionId : opcion.detailOptionId))}
                    onMouseEnter={() => setRowHoverIndex(key)}
                    onMouseLeave={() => setRowHoverIndex(-1)}
                  >
                    {opcion.isEdit && (
                      <div style={{ flex: '1 1 auto', textAlign: 'center' }}>
                        <Checkbox
                          checked={opcion.allowed}
                          className={classes.checkbox}
                          color="default"
                          onChange={event => onUpdateDataTable({ ...opcion, allowed: event.target.checked, 
                            startDate: event.target.checked ? new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()) : null,
                            endDate: event.target.checked ? new Date(2099, 11, 31) : null
                          })}
                        />
                      </div>
                    )}
                  </TableCell>
                  {opcion.children.map(function(child, index) {
                    if(index === 0) {
                      top = 0;
                    }
                    let newStyle = { ...style, height: getExpandedItemCount(child) * 26, top: top + 26 };
                    top += newStyle.height;

                    return renderItem({
                      columnIndex: columnIndex,
                      index: index,
                      key: key + '-' + index,
                      level: level + 1,
                      opcion: child,
                      style: newStyle 
                    });
                  })}
                </div>
              );
            } else {
              return (
                <TableCell className={clsx(classes.cell, { 
                    [classes.rowHover]: key === rowHoverIndex
                  })} key={key + '-' + columnIndex}
                  style={{ ...style, left: level === 0 ? style.left : 0 }}
                  onClick={() => setSelectedRow((opcion.detailOptionId === 0 ? opcion.optionId : opcion.detailOptionId))}
                  onMouseEnter={() => setRowHoverIndex(key)}
                  onMouseLeave={() => setRowHoverIndex(-1)}
                >
                  {opcion.isEdit && (
                    <div style={{ flex: '1 1 auto', textAlign: 'center' }}>
                      <Checkbox
                        checked={opcion.allowed}
                        className={classes.checkbox}
                        color="default"
                        onChange={event => onUpdateDataTable({ ...opcion, allowed: event.target.checked, 
                          startDate: event.target.checked ? new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()) : null,
                          endDate: event.target.checked ? new Date(2099, 11, 31) : null
                        })}
                      />
                    </div>
                  )}
                </TableCell>
              );
            }
          case 2:
            if(opcion.expanded) {
              return (
                <div key={key + '-' + columnIndex} style={{ ...style, left: level === 0 ? style.left : 0, display: 'flex', flexDirection: 'column' }}>
                  <TableCell className={clsx(classes.cell, { 
                      [classes.rowHover]: key === rowHoverIndex
                    })} key={key}
                    style={{ height: 26, width: style.width }}
                    onClick={() => setSelectedRow((opcion.detailOptionId === 0 ? opcion.optionId : opcion.detailOptionId))}
                    onMouseEnter={() => setRowHoverIndex(key)}
                    onMouseLeave={() => setRowHoverIndex(-1)}
                  >
                      {opcion.isEdit && selected === (opcion.detailOptionId === 0 ? opcion.optionId : opcion.detailOptionId) && (
                          <KeyboardDatePicker
                              autoOk
                              className={classes.datepicker}
                              disableToolbar
                              variant={matches ? "inline" : "dialog"}
                              clearable
                              value={opcion.startDate ? new Date(opcion.startDate) : null}
                              onChange={date => {
                                  if(!isBefore(date, new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) && isValid(date)) {
                                      onUpdateDataTable({ ...opcion, startDate: date });
                                  }
                              }}
                              minDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())}
                              format="dd/MM/yyyy"
                              inputVariant="standard"
                              invalidDateMessage=""
                              minDateMessage=""
                          />
                      )}
                      {opcion.startDate && selected !== (opcion.detailOptionId === 0 ? opcion.optionId : opcion.detailOptionId) ? format(new Date(opcion.startDate), 'dd/MM/yyyy') : ''}
                  </TableCell>
                  {opcion.children.map(function(child, index) {
                    if(index === 0) {
                      top = 0;
                    }
                    let newStyle = { ...style, height: getExpandedItemCount(child) * 26, top: top + 26 };
                    top += newStyle.height;

                    return renderItem({
                      columnIndex: columnIndex,
                      index: index,
                      key: key + '-' + index,
                      level: level + 1,
                      opcion: child,
                      style: newStyle
                    });
                  })}
                </div>
              );
            } else {
              return (
                <TableCell className={clsx(classes.cell, { 
                    [classes.rowHover]: key === rowHoverIndex
                  })} key={key + '-' + columnIndex}
                  style={{ ...style, left: level === 0 ? style.left : 0 }}
                  onClick={() => setSelectedRow((opcion.detailOptionId === 0 ? opcion.optionId : opcion.detailOptionId))}
                  onMouseEnter={() => setRowHoverIndex(key)}
                  onMouseLeave={() => setRowHoverIndex(-1)}
                >
                    {opcion.isEdit && selected === (opcion.detailOptionId === 0 ? opcion.optionId : opcion.detailOptionId) && (
                        <KeyboardDatePicker
                            autoOk
                            className={classes.datepicker}
                            disableToolbar
                            variant={matches ? "inline" : "dialog"}
                            clearable
                            value={opcion.startDate ? new Date(opcion.startDate) : null}
                            onChange={date => {
                                if(!isBefore(date, new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) && isValid(date)) {
                                    onUpdateDataTable({ ...opcion, startDate: date });
                                }
                            }}
                            minDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())}
                            format="dd/MM/yyyy"
                            inputVariant="standard"
                            invalidDateMessage=""
                            minDateMessage=""
                        />
                    )}
                    {opcion.startDate && selected !== (opcion.detailOptionId === 0 ? opcion.optionId : opcion.detailOptionId) ? format(new Date(opcion.startDate), 'dd/MM/yyyy') : ''}
                </TableCell>
              );
            }
            
          case 3:
            if(opcion.expanded) {
              return (
                <div key={key + '-' + columnIndex} style={{ ...style, left: level === 0 ? style.left : 0, display: 'flex', flexDirection: 'column' }}>
                  <TableCell className={clsx(classes.cell, { 
                      [classes.rowHover]: key === rowHoverIndex
                    })} key={key}
                    style={{ height: 26, width: style.width }}
                    onClick={() => setSelectedRow((opcion.detailOptionId === 0 ? opcion.optionId : opcion.detailOptionId))}
                    onMouseEnter={() => setRowHoverIndex(key)}
                    onMouseLeave={() => setRowHoverIndex(-1)}
                  >
                      {opcion.isEdit && selected === (opcion.detailOptionId === 0 ? opcion.optionId : opcion.detailOptionId) && (
                          <KeyboardDatePicker
                              autoOk
                              className={classes.datepicker}
                              disableToolbar
                              variant={matches ? "inline" : "dialog"}
                              clearable
                              value={opcion.endDate ? new Date(opcion.endDate) : null}
                              onChange={date => {
                                  if(!isBefore(date, new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) && isValid(date)) {
                                      onUpdateDataTable({ ...opcion, endDate: date });
                                  }
                              }}
                              minDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())}
                              format="dd/MM/yyyy"
                              inputVariant="standard"
                              invalidDateMessage=""
                              minDateMessage=""
                          />
                      )}
                      {opcion.endDate && selected !== (opcion.detailOptionId === 0 ? opcion.optionId : opcion.detailOptionId) ? format(new Date(opcion.endDate), 'dd/MM/yyyy') : ''}
                  </TableCell>
                  {opcion.children.map(function(child, index) {
                    if(index === 0) {
                      top = 0;
                    }
                    let newStyle = { ...style, height: getExpandedItemCount(child) * 26, top: top + 26 };
                    top += newStyle.height;

                    return renderItem({
                      columnIndex: columnIndex,
                      index: index,
                      key: key + '-' + index,
                      level: level + 1,
                      opcion: child,
                      style: newStyle
                    });
                  })}
                </div>
              );
            } else {
              return (
                <TableCell className={clsx(classes.cell, { 
                    [classes.rowHover]: key === rowHoverIndex
                  })} key={key + '-' + columnIndex}
                  style={{ ...style, left: level === 0 ? style.left : 0 }}
                  onClick={() => setSelectedRow((opcion.detailOptionId === 0 ? opcion.optionId : opcion.detailOptionId))}
                  onMouseEnter={() => setRowHoverIndex(key)}
                  onMouseLeave={() => setRowHoverIndex(-1)}
                >
                    {opcion.isEdit && selected === (opcion.detailOptionId === 0 ? opcion.optionId : opcion.detailOptionId) && (
                        <KeyboardDatePicker
                            autoOk
                            className={classes.datepicker}
                            disableToolbar
                            variant={matches ? "inline" : "dialog"}
                            clearable
                            value={opcion.endDate ? new Date(opcion.endDate) : null}
                            onChange={date => {
                                if(!isBefore(date, new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) && isValid(date)) {
                                    onUpdateDataTable({ ...opcion, endDate: date });
                                }
                            }}
                            minDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())}
                            format="dd/MM/yyyy"
                            inputVariant="standard"
                            invalidDateMessage=""
                            minDateMessage=""
                        />
                    )}
                    {opcion.endDate && selected !== (opcion.detailOptionId === 0 ? opcion.optionId : opcion.detailOptionId) ? format(new Date(opcion.endDate), 'dd/MM/yyyy') : ''}
                </TableCell>
              );
            }
          default:
            return (
              <TableCell className={clsx(classes.cell, { 
                  [classes.rowHover]: key === rowHoverIndex
                })} key={key + '-' + columnIndex}
                style={style}
                onClick={() => setSelectedRow((opcion.detailOptionId === 0 ? opcion.optionId : opcion.detailOptionId))}
                onMouseEnter={() => setRowHoverIndex(key)}
                onMouseLeave={() => setRowHoverIndex(-1)}
              >
                {opcion[columns[columnIndex].dataKey]}
              </TableCell>
            );
        }

    }

    const renderHeaderCell = ({ columnIndex, key, rowIndex, style }) => {
        return (
          <TableCell className={classes.headerCell} key={key} style={style}>
            <div style={{ flex: 'auto', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {columns[columnIndex].label}
            </div>
            <Draggable
              axis="x"
              defaultClassName={classes.dragHandle}
              defaultClassNameDragging={classes.dragHandleActive}
              onDrag={(event, { deltaX }) =>
                resizeRow({
                  column: columns[columnIndex],
                  deltaX
                })
              }
              position={{ x: 0 }}
              zIndex={999}
            >
              <span className={classes.dragHandleIcon}></span>
            </Draggable>
          </TableCell>
        );
    }

    const renderRowCell = ({ columnIndex, key, rowIndex, style }) => {
      return renderItem({ columnIndex, index: rowIndex, key: rowIndex, level: 0, opcion: data[rowIndex], style});
    }

    const getExpandedItemCount = item => {
        let count = 1;
        if (item.expanded) {
            count += item.children
            .map(getExpandedItemCount)
            .reduce(function(total, count) {
                return total + count;
            }, 0);
        }
        return count;
    }

    const getRowHeight = index => {
        return getExpandedItemCount(data[index]) * 26;
    }

    return (
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados">
          <div className={classes.tableWrap}>
              <ScrollSync>
                  {({ onScroll, scrollLeft }) => (
                      <AutoSizer >
                          {({ height, width }) => (
                              <div>
                                  <Grid
                                      cellRenderer={renderHeaderCell}
                                      className={classes.header}
                                      columnCount={columns.length}
                                      columnWidth={({ index }) => columns[index].width }
                                      height={26}
                                      ref={headerEl}
                                      rowCount={1}
                                      rowHeight={26}
                                      scrollLeft={scrollLeft}
                                      style={{ outline: 'none' }}
                                      width={width}
                                  />
                                  <Grid
                                      cellRenderer={renderRowCell}
                                      columnCount={columns.length}
                                      columnWidth={({ index }) => columns[index].width}
                                      height={height - 26}
                                      onScroll={onScroll}
                                      ref={bodyEl}
                                      rowCount={data.length}
                                      rowHeight={({ index }) => getRowHeight(index) }
                                      style={{ outline: 'none' }}
                                      width={width}
                                  />
                              </div>
                          )}
                      </AutoSizer>
                  )}
              </ScrollSync>
              {loading && (<div className={classes.center}>
                  <CircularProgress color="primary"/>
              </div>)}
              {data.length === 0 && !loading && (<div className={classes.textNoData}>
                  No se encontraron registros
              </div>)}
            </div>
        </FieldSet>
    );
}

const mapStateToProps = state => ({
    columns: state.permissions.table.columns,
    data: state.permissions.table.data,
    loading: state.permissions.table.loading,
    selected: state.permissions.table.selected
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(PermissionsList);