import Permissions from '../pages/Permissions';
import User from '../pages/User';

export default {
    Permissions: {
        component: Permissions,
        viewType: 'security-permissionsview',
        paths: [
            '/seguridad/permisos'
        ]
    },
    User: {
        component: User,
        viewType: 'security-userview',
        paths: [
            '/seguridad/usuario', 
            '/seguridad/usuario/crear',
            '/seguridad/usuario/:id',
            '/seguridad/usuario/:id/editar',
            '/seguridad/usuario/:id/resetear'
        ]
    }
};