import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { FieldSet, Table } from '@sigeco/tools';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Header from '../../components/main/Header'
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
    button: {
        fontFamily: 'inherit',
        fontSize: '0.75rem',
        padding: '0.125rem 0.5rem',
        textTransform: 'none'
    },
    content: {
        flex: '1 1 auto',
        padding: '0.5rem 0.75rem'
    },
    dialog: {
        '@media (min-width: 701px)': {
            borderColor: theme.palette.primary.main,
            borderStyle: 'solid',
            borderWidth: '0.125rem',
            height: '60%',
            width: 600
        }
    },
    dialogActions: {
        backgroundColor: theme.palette.primary.main + '14',
        padding: '0 0.5rem 0.5rem'
    },
    dialogContent: {
        backgroundColor: theme.palette.primary.main + '14',
        display: 'flex',
        flexDirection: 'column',
        padding: '0 0.5rem 0.5rem',
        '@media (min-width: 701px)': {
            padding: '0 0.75rem 0.75rem'
        },
        '@media (min-width: 1401px)': {
            padding: '0 1rem 1rem'
        }
    },
    dialogTitle: {
        backgroundColor: theme.palette.primary.main + '14',
        padding: 0
    },
    paper: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        marginTop: '0.5rem'
    },
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const AdvancedSearch = props => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:701px)');
    const userCallback = props.fnCallback || function() { return undefined; };
    const firstUpdate = useRef(true);
    const [data, setData] = useState(props.data || []);
    const [fields, setFields] = useState(props.fields || [])
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [selected, setSelected] = useState([]);
    const [total, setTotal] = useState(props.data ? props.data.length : 0);

    useEffect(()=> {
        if(props.autoLoad) {
            getDataAsync();
        }
    // eslint-disable-next-line
    }, [props.autoLoad]);

    useEffect(() => {
        if(orderBy !== '') {
            getDataAsync();
        }
    // eslint-disable-next-line
    }, [order, orderBy]);

    useEffect(() => {
        if(firstUpdate.current) {
            firstUpdate.current = false;
        }
        else {
            getDataAsync();
        }
    // eslint-disable-next-line
    }, [page, rowsPerPage]);

    async function getDataAsync() {
        if(!props.remote) {
            return;
        }

        try {
            setLoading(true);

            let params = { ...filters };

            if(props.paginated) {
                params['pageSize'] = rowsPerPage;
                params['start'] = page*rowsPerPage;
            }

            if(orderBy !== '') {
                params['sort'] = JSON.stringify([{ 'property': orderBy, 'direction': order.toUpperCase() }]);
            }

            let result = await props.getData(params);

            setData(result.data);
            setTotal(result.total);
        } catch (error) {
            setData([]);
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const store = props.remote ? data : stableSort(data, getSorting(order, orderBy))
        .slice(props.paginated ? page * rowsPerPage : 0, props.paginated ? page * rowsPerPage + rowsPerPage : data.length);
    

    function desc(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
          
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }

        return 0;
    }

    function getSorting(order, orderBy) {
        return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
    }

    function stableSort(array, cmp) {
        const stabilizedThis = array.map((el, index) => [el, index]);

        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
          
            if (order !== 0) return order;
            return a[1] - b[1];
        });

        return stabilizedThis.map(el => el[0]);
    }

    async function handleFilterClick() {
        setSelected([]);
        
        if(props.remote) {
            getDataAsync();
        }
        else {
            let result = [...props.data];
            for (const prop in filters) {
                if(filters[prop] !== '') {
                    result = result.filter(f => f[prop].toUpperCase().indexOf(filters[prop].toUpperCase()) !== -1);
                }
            }
            setData(result);
        }
    }

    function onFieldChange(event) {
        const { name, value } = event.target;

        setFilters(prevState => ({ ...prevState, [name]: value }));
    }

    function onHandleClose(btn) {
        userCallback(btn, selected);
    }

    const handleChangePage = (newPage) => {
        if(!props.multiSelect) {
            setSelected([]);
        }
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = event => {
        if(!props.multiSelect) {
            setSelected([]);
        }
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';

        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const onUpdateColumn = column => {
        let columns = [...fields];

        for(var i = 0; i < columns.length; i++) {
            if(columns[i].dataKey === column.dataKey) {
                columns[i].width = column.width;
            }
        }

        setFields(columns);
    };

    return (
        <Dialog
            classes={{
                paper: classes.dialog
            }}
            fullScreen={!matches}
            open={props.open}
        >
            <DialogTitle className={classes.dialogTitle} disableTypography>
                <Header style={{ paddingLeft: '0.5rem' }} iconCls="fas fa-search" subtitle="Búsqueda Avanzada" title={props.title} />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
               {props.fields.filter(f => f.search).length > 0 && (<div>
                    <FieldSet  iconCls="fas fa-filter" title="Filtros" defaultExpanded expandable>
                        <div className={classes.content}>
                            <Grid  container spacing={1}>
                                {props.fields.filter(f => f.search).map((field, key) => (
                                    <Grid item key={key} xs={12} container>
                                        <Grid item xs={matches ? 6 : 12}>
                                            <TextField
                                                autoComplete="off"
                                                autoFocus={key === 0}
                                                fullWidth
                                                label={field.label}
                                                name={field.filterName || field.dataKey}
                                                onChange={onFieldChange}
                                                value={filters[field.filterName || field.dataKey] || ''}
                                                variant={matches ? "outlined" : "standard"}
                                            />
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                            <div style={{ marginTop: '0.4rem', textAlign: 'end' }}>
                                <Button className={classes.button} color="primary" size="small" variant="contained" onClick={handleFilterClick}>
                                    Filtrar
                                </Button>
                            </div>
                        </div>
                    </FieldSet>
                </div>)}
                <FieldSet className={classes.paper} iconCls="fas fa-th-list" title="Resultados">
                    <div className={classes.tableWrap}>
                        <Table
                            columns={props.fields}
                            count={total}
                            loading={loading}
                            multiSelect={props.multiSelect}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            onRowClick={(items) => setSelected(items)}
                            onRowDoubleClick={() => onHandleClose('yes')}
                            onSort={datakey => handleRequestSort(datakey)}
                            onUpdateColumn={onUpdateColumn}
                            page={page}
                            paginate={props.paginated}
                            rowCount={store.length}
                            rowGetter={index => store[index]}
                            rowsPerPage={rowsPerPage}
                            selected={selected}
                            sortBy={orderBy}
                            sortDirection={order}
                        />
                    </div>
                </FieldSet>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button className={classes.button} onClick={() => onHandleClose('yes')} disabled={selected.length === 0} color="primary" size="small" variant="contained">
                    Aceptar
                </Button>
                <Button className={classes.button} color="primary" onClick={() => onHandleClose('no')} size="small" variant="contained">
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

AdvancedSearch.propTypes = {
    data: PropTypes.array,
    fields: PropTypes.array,
    open: PropTypes.bool.isRequired,
    paginated: PropTypes.bool,
    title: PropTypes.string
}
  
export default AdvancedSearch;