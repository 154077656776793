import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    width: '19.2rem',
    '@media (min-width: 1401px)': {
      width: '24rem'
    }
  },
  root: {
    alignItems: 'center',
    color: '#f00',
    display: 'flex',
    justifyContent: 'center',
    height: '100%'
  },
  title: {
    fontSize: '3.84rem',
    margin: 'auto',
    '@media (min-width: 701px)': {
      fontSize: '4.8rem'
    },
    '@media (min-width: 1401px)': {
      fontSize: '6rem'
    }
  },
  text: {
    fontSize: '0.96rem',
    margin: 'auto',
    '@media (min-width: 701px)': {
      fontSize: '1.2rem'
    },
    '@media (min-width: 1401px)': {
      fontSize: '1.5rem'
    }
  }
}));

export default ({ error }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
          <div className={classes.container}>
            <h1 className={classes.title}>400</h1>
            <p className={classes.text}>Error: {error}</p>
          </div>
        </div>
    );
}