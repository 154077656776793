import {
    CLEAN_FORM,
    DROP_COMPANY,
    ERROR_USERS_LIST,
    RECEIVE_USERS_LIST,
    REQUEST_USERS_LIST,
    RESTART_ERRORS,
    RESTART_STATE,
    SELECT_PRINCIPAL,
    SET_ENTITY,
    SET_ERROR,
    SET_MASK,
    SET_ORDER,
    SET_PAGE,
    SET_PAGE_SIZE,
    SET_PASSWORD,
    SET_SELECTED_ROW,
    SET_STATE_FORM,
    UPDATE_FIELD_ENTITY,
    UPDATE_FIELD_FILTER,
    UPDATE_TABLE_COLUMN
} from './types';

const initialState = {
    formState: 'search',
    loading: false,
    loadingStore: false,
    entity: {
        usuarioId: '',
        nombre: '',
        nombreUsuario: '',
        clave: '',
        correo: '',
        telefono: '',
        nroDocumento: '',
        primerNombre: '',
        segundoNombre: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        expiraClaveHabilitado: false,
        bloqueoHabilitado: false,
        activo: true,
        empresas: []
    },
    errors: {
        nombre: '',
        nombreUsuario: '',
        correo: '',
        nroDocumento: '',
        primerNombre: '',
        apellidoPaterno: ''
    },
    filters: {
        usuario: '',
        nombre: ''
    },
    store: {
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        selected: [],
        source: null
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: [
            { dataKey: 'nombre', label: 'Nombres', width: 275 },
            { dataKey: 'nombreUsuario', label: 'Usuario', width: 150 },
            { dataKey: 'correo', label: 'Correo', width: 200 },
            { dataKey: 'telefono', label: 'Teléfono', width: 105 },
            { type: 'boolean', dataKey: 'activo', label: 'Activo', width: 90 }
        ]
    }
};

export const userReducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case CLEAN_FORM:
            return {
                ...state,
                entity: initialState.entity,
                errors: initialState.errors
            };
        case DROP_COMPANY:
            return {
                ...state,
                entity: {
                    ...state.entity,
                    empresas: state.entity.empresas.filter(f => f.empresaId !== action.empresaId)
                }
            };
        case ERROR_USERS_LIST:
            return {
                ...state,
                loadingStore: false,
                store: {
                    ...state.store,
                    start: 0,
                    total: 0,
                    data: [],
                    source: null
                }
            };
        case RECEIVE_USERS_LIST:
            return {
                ...state,
                loadingStore: false,
                store: {
                    ...state.store,
                    total: action.total,
                    data: action.payload,
                    source: null
                }
            };
        case REQUEST_USERS_LIST:
            return {
                ...state,
                loadingStore: true,
                store: {
                    ...state.store,
                    source: action.source
                }
            };
        case RESTART_ERRORS:
            return {
                ...state,
                errors: initialState.errors
            }
        case RESTART_STATE:
            return {
                ...initialState
            };
        case SELECT_PRINCIPAL:
            return {
                ...state,
                entity: {
                    ...state.entity,
                    empresas: action.payload
                }
            };
        case SET_ENTITY:
            return {
                ...state,
                entity: {
                    ...state.entity,
                    usuarioId: action.payload.usuarioId,
                    nombre: action.payload.nombre,
                    nombreUsuario: action.payload.nombreUsuario,
                    correo: action.payload.correo,
                    telefono: action.payload.telefono || '',
                    nroDocumento: action.payload.nroDocumento,
                    primerNombre: action.payload.primerNombre,
                    segundoNombre: action.payload.segundoNombre,
                    apellidoPaterno: action.payload.apellidoPaterno,
                    apellidoMaterno: action.payload.apellidoMaterno,
                    expiraClaveHabilitado: action.payload.expiraClaveHabilitado,
                    bloqueoHabilitado: action.payload.bloqueoHabilitado,
                    activo: action.payload.activo,
                    empresas: action.payload.empresas
                }
            };
        case SET_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.key]: action.error
                }
            }
        case SET_MASK:
            return {
                ...state,
                loading: action.loading
            };
        case SET_ORDER:
            return {
                ...state,
                table: {
                    ...state.table,
                    order: action.order,
                    orderBy: action.orderBy
                }
            };
        case SET_PAGE:
            return {
                ...state,
                store: {
                    ...state.store,
                    start: state.store.pageSize*action.page
                }
            }
        case SET_PAGE_SIZE:
            return {
                ...state,
                store: {
                    ...state.store,
                    pageSize: action.pageSize
                }
            };
        case SET_PASSWORD: {
            return {
                ...state,
                entity: {
                    ...state.entity,
                    clave: action.password
                }
            }
        }
        case SET_SELECTED_ROW:
            return {
                ...state,
                store: {
                    ...state.store,
                    selected: action.selected
                }
            };
        case SET_STATE_FORM:
            return {
                ...state,
                formState: action.formState
            };
        case UPDATE_FIELD_ENTITY:
            return { 
                ...state,
                entity: {
                    ...state.entity,
                    [action.key]: action.value
                }
            };
        case UPDATE_FIELD_FILTER:
            return { 
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
        case UPDATE_TABLE_COLUMN:
            return {
                ...state,
                table: {
                    ...state.table,
                    columns: action.columns
                }
            };
        default:
            return state;
    }

};