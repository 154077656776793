import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { LinkContainer } from 'react-router-bootstrap';

import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/main/actions';

const useStyles = makeStyles((theme) => ({
    container: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    grupo: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    item: {
        borderRadius: '0.25rem',
        color: '#fff',
        paddingBottom: '0.1rem',
        paddingLeft: '0.4rem',
        paddingRight: '0.4rem',
        paddingTop: '0.1rem',
        '&:hover': {
            backgroundColor: 'rgba(200, 200, 200, 0.2)'
        },
        '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        },
        '&.Mui-selected:hover': {
            backgroundColor: theme.palette.primary.main
        },
        '@media (min-width: 1401px)': {
            paddingBottom: '0.125rem',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
            paddingTop: '0.125rem'
        }
    },
    icono: {
        color: 'inherit',
        marginRight: '0.6rem',
        minWidth: '1.2rem',
        '@media (min-width: 1401px)': {
            marginRight: '0.75rem',
            minWidth: '1.5rem'
        }
    },
    lista: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        flexGrow: 1,
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    listText: {
        marginBottom: '0.2rem',
        marginTop: '0.2rem',
        '@media (min-width: 1401px)': {
            marginBottom: '0.25rem',
            marginTop: '0.25rem'
        }
    },
    svgList: {
        fontSize: '0.8125rem',
        width: 'auto',
        '@media (min-width: 1401px)': {
            fontSize: '1rem'
        }
    },
    texto: {
        fontFamily: 'inherit',
        fontSize: '0.65rem',
        '@media (min-width: 1401px)': {
            fontSize: '0.8125rem',
        }
    },
    root: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        flexGrow: 1
    },
}));

const TreeList = ({ id, hover, load, navs, opciones, open, viewType, search, fnToggleOpen }) => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:1401px)');
    const [state, setState] = useState({});

    useEffect(() => {
        setState({});
        searchSelected(opciones);
    // eslint-disable-next-line
    }, [opciones]);

    function searchSelected(items, parents) {
        items.forEach(function (item){
            if(item.children) {
                let p = parents || [];

                p.push(item.text);
                searchSelected(item.children, p)
            }
            else {
                if(item.viewType === viewType && parents) {
                    parents.forEach(parent => setState(prevState => ({ ...prevState, [parent]: true })));
                }
            }
        });
    }

    function handleClick(item) {
        setState(prevState => ({ ...prevState, [item]: !prevState[item] }));
    }

    function getListItems(opciones, level = 0) {
        return opciones.map((opcion, key) => {
            if(!opcion.children) {
                let nav = navs.filter(n => n.viewType === opcion.viewType)[0];
                let ruta = opcion.ruta ? opcion.ruta.split(';')[0] : ''

                return (
                    <div key={key} style={{ 
                        marginLeft: (matches ? `${0.75 + level*2.25}rem` : `${0.6 + level*1.8}rem`), 
                        width: `calc(100% - ${(matches ? `${1.5 + level*2.25}rem` : `${1.2 + level*1.8}rem`)}` }}
                    >
                        <LinkContainer to={opcion.reactivo ? `${nav ? nav.path : ruta}${search}` : `/app${search}#view=${opcion.viewType}`}>
                            <ListItem button className={classes.item} key={opcion.text} selected={viewType === opcion.viewType} onClick={() => { if(fnToggleOpen !== undefined) fnToggleOpen() }} >
                                <ListItemIcon className={classes.icono}><Icon className={'fas ' + opcion.iconCls.split(" ")[1]} classes={{root: classes.svgList }} /></ListItemIcon>
                                <ListItemText classes={{primary: classes.texto, root: classes.listText}} primary={opcion.text} />
                            </ListItem>
                        </LinkContainer>
                    </div>
                )
            }

            return (
                <div className={state[ opcion.text ] && (open || hover) ? classes.grupo : ''} key={opcion.text}>
                    <ListItem 
                        button 
                        className={classes.item} 
                        key={opcion.text} 
                        style={{ 
                            marginLeft: (matches ? `${0.75 + level*2.25}rem` : `${0.6 + level*1.8}rem`), 
                            width: `calc(100% - ${(matches ? `${1.5 + level*2.25}rem` : `${1.2 + level*1.8}rem`)}` }}
                            onClick={() => handleClick(opcion.text)} 
                        >
                        <ListItemIcon className={classes.icono}><Icon className={'fas ' + opcion.iconCls.split(" ")[1]} classes={{root: classes.svgList }}/></ListItemIcon>
                        <ListItemText classes={{primary: classes.texto}} primary={ opcion.text } />
                        { state[ opcion.text ] && (open || hover) ? <Icon className={clsx(classes.svgList, "fas fa-angle-up")} /> : <Icon className={clsx(classes.svgList, "fas fa-angle-down")} /> }
                    </ListItem>
                    <Collapse in={state[ opcion.text ] && (open || hover)} timeout="auto" unmountOnExit>
                        {getListItems(opcion.children, level + 1)}
                    </Collapse>
                </div>
            )
        })
    }

    if( load ) {
        return (
            <List id={id} className={classes.root}>
                <div className={classes.container}>
                    <CircularProgress color="primary"/>
                </div>
            </List>
        )
    }

    return (
        <List id={id} className={classes.lista}>
            {getListItems(opciones)}
        </List>
    )
}

const mapStateToProps = state => ({
    load: state.main.loadOptions,
    navs: state.main.navs,
    opciones: state.main.opciones,
    search: state.router.location.search,
    viewType: state.main.viewType
});
  
export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(TreeList);