import React from 'react';
import { IdentityUrl } from '../../config';
import { LinkContainer } from 'react-router-bootstrap';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/session/actions';

const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: theme.palette.primary.main,
        fontSize: '1rem',
        height: '1.8rem',
        marginRight: '0.4rem',
        width: '1.8rem',
        '@media (min-width: 1401px)': {
            fontSize: '1.25rem',
            height: '2.25rem',
            marginRight: '0.5rem',
            width: '2.25rem',
        }
    },
    boton: {
        color: '#666',
        fontFamily: 'inherit',
        fontSize: '0.55rem',
        '@media (min-width: 1401px)': {
            fontSize: '0.6875rem'
        }
    },
    botonera: {
        backgroundColor: '#f5f5f5',
        borderTop: '1px solid #ccc'
    },
    contenido: {
        padding: 0, 
        width: '16rem',
        '@media (min-width: 1401px)': {
            width: '20rem'
        }
    },
    foto: {
        backgroundColor: theme.palette.primary.main, 
        fontSize: '1.6rem',
        marginRight: '0.8rem',
        height: '3.6rem', 
        width: '3.6rem',
        '@media (min-width: 1401px)': {
            fontSize: '2rem',
            marginRight: '1rem',
            height: '4.5rem', 
            width: '4.5rem'
        }
    },
    grow: {
        flexGrow: 1
    },
    margen: {
        display: 'flex', 
        margin: '0.8rem',
        '@media (min-width: 1401px)': {
            margin: '1rem'
        }
    },
    primaryText: {
        fontSize: '0.7rem',
        lineHeight: 1,
        '@media (min-width: 1401px)': {
            fontSize: '0.875rem'
        }
    },
    secondaryText: {
        color: 'rgba(0, 0, 0, 0.54);',
        fontSize: '0.65rem',
        '@media (min-width: 1401px)': {
            fontSize: '0.8125rem'
        }
    },
    text: {
        fontSize: '0.65rem', 
        marginTop: '0.1rem', 
        padding: 1,
        '@media (min-width: 1401px)': {
            fontSize: '0.8125rem', 
            marginTop: '0.125rem'
        }
    },
    title: {
        fontSize: '0.9rem', 
        fontWeight: 700,
        '@media (min-width: 1401px)': {
            fontSize: '1.125rem', 
        }
    }
}));

const UserCard = ({ accounts, avatar, nombre, search, usuario, onHandleClose}) => {
    const classes = useStyles();

    function handleAddAccount() {
        onHandleClose(null);
        window.open(`${IdentityUrl}/oauth/v2/?continue=${encodeURIComponent(window.location.href)}`, '_blank');
    }

    function handleSignOut() {
        window.location.href = `${IdentityUrl}/logout?continue=${encodeURIComponent(window.location.href)}`;
    }

    function handleClick(authuser) {
        onHandleClose(null);
        if(authuser >= 0) {
            window.open(`/?authuser=${authuser}`, '_blank');
        }
        else {
            window.open(`${IdentityUrl}/oauth/v2/?continue=${encodeURIComponent(window.location.origin)}`, '_blank');
        }
        
    }
    
    return (
        <Card>
            <CardContent className={classes.contenido}>
                <div className={classes.margen}>
                    <div>
                        <Avatar src={avatar ? `data:image/jpeg;base64,${avatar}` : ''} className={classes.foto}>
                            {nombre.charAt(0).toUpperCase() + (nombre.split(' ').length > 1 ? nombre.split(' ')[1].charAt(0).toUpperCase() : '') }
                        </Avatar>
                    </div>
                    <div>
                        <div className={classes.title}>{nombre}</div>
                        <div className={classes.text}>{usuario}</div>
                        <div className={classes.text}>
                            <LinkContainer to={`/perfil${search}`}>
                                <Link style={{ cursor: 'pointer' }} onClick={() => onHandleClose(null)}>
                                    Administra tu cuenta
                                </Link>
                            </LinkContainer>
                        </div>
                    </div>
                </div>
                <List style={{ padding: 0 }}>
                    {accounts.filter(f => f.nombreUsuario !== usuario).map((account, key) => 
                        <ListItem className={classes.botonera} button key={key} onClick={() => handleClick(account.authuser)}>
                            <Avatar variant="square" src={account.avatar ? `data:image/jpeg;base64,${account.avatar}` : ''} className={classes.avatar}>
                                {account.nombre.charAt(0).toUpperCase() + (account.nombre.split(" ").length > 1 ? account.nombre.split(" ")[1].charAt(0).toUpperCase() : '') }
                            </Avatar>
                            <ListItemText
                                primary={<span className={classes.primaryText}>{account.nombre}</span>}
                                secondary={<span className={classes.secondaryText}>{account.nombreUsuario}</span>}
                            />
                            <ListItemSecondaryAction>
                                <div className={classes.secondaryText}>{account.autenticado ? '' : 'Sesión finalizada'}</div>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )}
                </List>
            </CardContent>
            <CardActions className={classes.botonera}>
                <Button variant='outlined' className={classes.boton} onClick={handleAddAccount} >Agregar Cuenta</Button>
                <div className={classes.grow}></div>
                <Button variant='outlined' className={classes.boton} onClick={handleSignOut} >Salir</Button>
            </CardActions>
        </Card>
    )
}

const mapStateToProps = state => ({
    accounts: state.session.accounts,
    avatar: state.session.user.avatar,
    nombre: state.session.user.nombre,
    search: state.router.location.search,
    usuario: state.session.user.usuario
});
  
export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({...actionCreators}, dispatch)
)(UserCard);