import React, { useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/session/actions';

const useStyles = makeStyles(() => ({
    content: {
        flex: '1 1 auto',
        overflow: 'auto'
    },
    contentInfo: {
        marginTop: '1.6rem',
        '@media (min-width: 1401px)': {
            marginTop: '2rem'
        }
    },
    loader: {
        left:'calc(50% - 20px)',
        position: 'absolute',
        top:'calc(50% - 20px)'
    },
    logo: {
        width: '75%'
    },
    panelIn: {
        borderRadius: 0,
        boxShadow: '0 8px 8px 0 rgba(0,0,0,.16)',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 2.2rem)',
        minWidth: '17.5rem',
        paddingLeft: '1.2rem',
        paddingRight: '1.2rem',
        position: 'absolute',
        right: 0,
        top: '2.25rem',
        width: '17.5rem',
        zIndex: 1201,
        '@media (min-width: 1401px)': {
            height: 'calc(100% - 2.75rem)',
            minWidth: '21.875rem',
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
            top: '2.75rem',
            width: '21.875rem'
        }
    },
    subtitle: {
        color: '#595950',
        fontFamily: 'inherit',
        fontSize: '0.7rem',
        fontWeight: '600',
        lineHeight: '1.8rem',
        '@media (min-width: 1401px)': {
            fontSize: '0.875rem',
            lineHeight: '2.25rem'
        }
    },
    text: {
        color: '#595950',
        fontFamily: 'inherit',
        fontSize: '0.65rem',
        lineHeight: '1.2rem',
        '@media (min-width: 1401px)': {
            fontSize: '0.8125rem',
            lineHeight: '1.5rem',
        }
    },
    textSelect: {
        color: '#595950',
        fontSize: '0.65rem',
        paddingBottom: '0.125rem',
        paddingTop: '0.125rem',
        '@media (min-width: 1401px)': {
            fontSize: '0.8125rem'
        }
    },
    textPrimary: {
        color: '#595950',
        fontFamily: 'inherit',
        fontSize: '0.7rem',
        '@media (min-width: 1401px)': {
            fontSize: '0.875rem'
        }
    },
    textSecondary: {
        fontFamily: 'inherit',
        fontSize: '0.65rem',
        '@media (min-width: 1401px)': {
            fontSize: '0.8125rem'
        }
    },
    title: {
        fontSize: '0.8rem',
        fontWeight: 600,
        marginBottom: '1rem',
        marginTop: '1rem',
        '@media (min-width: 1401px)': {
            fontSize: '1rem',
            marginBottom: '1.25rem',
            marginTop: '1.25rem'
        }
    }
}));

const Company = ({ companies, company, isLoadCompany, parameters, getCompanies, UpdateCompanyPrincipal }) => {
    const classes = useStyles();

    useEffect(() => {
        getCompanies();
    // eslint-disable-next-line
    }, []);

    function onHandleItemClick(item) {
        let params = new URLSearchParams(window.location.search);
        params.set('company_code', item.codigo);

        window.location.href = `${window.location.origin}${window.location.pathname}?${params.toString()}${window.location.hash}`;
    }

    return (
        <Paper className={classes.panelIn}>
            <div className={classes.title}>Tu Empresa</div>
            {!isLoadCompany && (<div className={classes.loader}>
                <CircularProgress color="primary"/>
            </div>)}
            {isLoadCompany && (<div className={classes.content}>
                <img alt="logo-empresa" className={classes.logo} src={company.logo}/>
                <div className={classes.contentInfo}>
                    <Typography className={classes.subtitle}>Información</Typography>
                    <Typography className={classes.text}>RUC: {company.ruc}</Typography>
                    <Typography className={classes.text}>Razón Social: {company.razonSocial}</Typography>
                    <Typography className={classes.text}>Nombre Comercial: {company.nombreComercial}</Typography>
                </div>
                {companies.length > 1 && (<div style={{ marginTop: 36 }}>
                    <Typography className={classes.subtitle}>Opciones</Typography>
                    <Typography className={classes.text}>Establecer empresa prederteminada:</Typography>
                    <Select
                        classes={{selectMenu: classes.text}}
                        fullWidth
                        inputProps={{
                            className: classes.textSelect
                        }}
                        style={{ fontFamily: 'inherit' }}
                        value={parameters.empresaId}
                        onChange={ event => UpdateCompanyPrincipal(event.target.value)}
                    >
                        {companies.map((item, key) => (
                            <MenuItem className={classes.text} key={key} value={item.empresaId}>
                                {item.razonSocial}
                            </MenuItem>
                        ))}
                    </Select>
                    <div style={{ marginTop: 24 }}>
                        <Typography className={classes.text}>Cambiar a:</Typography>
                        <List component="nav" style={{ paddingTop: 0 }}>
                            {companies.filter(c => c.empresaId !== company.empresaId).map(item => (
                                <div key={item.empresaId}>
                                    <ListItem button style={{ padding: 0 }} onClick={() => onHandleItemClick(item)}>
                                        <ListItemText classes={{primary: classes.textPrimary, secondary: classes.textSecondary}} primary={item.razonSocial} secondary={item.ruc} />
                                    </ListItem>
                                    <Divider variant="fullWidth" component="li" />
                                </div>
                            ))}
                        </List>
                    </div>
                </div>)}
            </div>)}
        </Paper>
    )
}

const mapStateToProps = state => ({
    companies: state.session.companies,
    company: state.session.company,
    isLoadCompany: state.session.isLoadCompany,
    parameters: state.session.parameters
});
  
export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Company);