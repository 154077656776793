import {
    RECEIVE_COMPANIES,
    RECEIVE_SESSION,
    SET_ACCOUNTS,
    SET_AVATAR,
    SET_DATA_COMPANY, 
    SET_DATA_USER,
    SET_PARAMETERS
} from './types';

const initialState = {
    accessToken: '',
    expiresIn: 0,
    tokenType: '',
    companies: [],
    company: {
        codigo: '',
        empresaId: 0,
        exoneradoIGV: false,
        incluyeIGV: true,
        logo: '',
        nombreComercial: '',
        razonSocial: '',
        ruc: '',
    },    
    isLoadCompany: false,
    user: {
        usuarioId: '',
        usuario: '',
        nombre: '',
        avatar: ''
    },
    parameters: {
        empresaId: 0
    },
    accounts: []
};

export const sessionReducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case RECEIVE_COMPANIES:
            return {
                ...state,
                companies: action.payload,
                isLoadCompany: true
            };
        case RECEIVE_SESSION:
            return {
                ...state,
                accessToken: action.accessToken,
                expiresIn: action.expiresIn,
                tokenType: action.tokenType
            };
        case SET_ACCOUNTS:
            return {
                ...state,
                accounts: action.payload
            };
        case SET_AVATAR:
            return {
                ...state,
                user: {
                    ...state.user,
                    avatar: action.avatar
                }
            };
        case SET_DATA_COMPANY:
            return {
                ...state,
                company: {
                    codigo: action.payload.codigo,
                    empresaId: action.payload.empresaId,
                    exoneradoIGV: action.payload.exoneradoIGV,
                    incluyeIGV: action.payload.incluyeIGV,
                    logo: action.payload.logo,
                    nombreComercial: action.payload.nombreComercial,
                    razonSocial: action.payload.razonSocial,
                    ruc: action.payload.ruc
                }
            };
        case SET_DATA_USER:
            return {
                ...state,
                user: {
                    usuarioId: action.usuarioId,
                    usuario: action.usuario,
                    nombre: action.nombre,
                    avatar: action.avatar
                }
            };
        case SET_PARAMETERS:
            return {
                ...state,
                parameters: {
                    empresaId: action.empresaId
                }
            };
        default:
            return state;
    }

};