import React from 'react';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/profile/actions';

const useStyles = makeStyles(() => ({
    button: {
        fontFamily: 'inherit',
        fontSize: '0.75rem',
        marginRight: '0.5rem',
        padding: '0.125rem 0.5rem',
        textTransform: 'none'
    },
    divider: {
        marginBottom: '1rem',
        marginTop: '1rem'
    },
    footer: {
        display: 'flex',
        flex: '0 0 auto',
        justifyContent: 'center',
        marginTop: '1rem',
        '@media (min-width: 701px)': {
            justifyContent: 'flex-start',
        }
    },
    root: {
        flex: '1 1 auto',
        padding: '1rem'
    },
    title: {
        fontSize: '0.875rem',
        fontWeight: 500,
        marginBottom: '1.5rem'
    }
}));

const ProfilePassword = ({ passwords, btnChangePassword, onShowPassword, onUpdateFieldPassword, resetTextPassword }) => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:701px)');

    return (
        <div className={classes.root}>
            <div className={classes.title}>Cambia Tu Contraseña</div>
                <Grid container spacing={2} >
                    <Grid item xs={12} container>
                        <Grid item xs={matches ? 4 : 12}>
                            <TextField
                                autoComplete="off"
                                error={passwords.error !== ''}
                                fullWidth
                                helperText={passwords.error}
                                label="Contraseña actual"
                                name="current"
                                type={passwords.show ? 'text' : 'password'}
                                value={passwords.current}
                                variant={matches ? "outlined" : "standard"}
                                onChange={(e) => onUpdateFieldPassword(e.target.name, e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Mostrar contraseña" placement="top-start" arrow>
                                                <IconButton 
                                                    className={passwords.show ? "fas fa-eye" : "fas fa-eye-slash"} 
                                                    onClick={() => onShowPassword('show')}
                                                    style={{ fontSize: '1rem' }}
                                                />
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}container>
                        <Grid item xs={matches ? 4 : 12}>
                            <TextField
                                autoComplete="off"
                                error={passwords.errorNew !== ''}
                                fullWidth
                                helperText={passwords.errorNew}
                                label="Nueva contraseña"
                                name="new"
                                type={passwords.showNew ? 'text' : 'password'}
                                value={passwords.new}
                                variant={matches ? "outlined" : "standard"}
                                onChange={(e) => onUpdateFieldPassword(e.target.name, e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Mostrar contraseña" placement="top-start" arrow>
                                                <IconButton 
                                                    className={passwords.showNew ? "fas fa-eye" : "fas fa-eye-slash"} 
                                                    onClick={() => onShowPassword('showNew')}
                                                    style={{ fontSize: '1rem' }}
                                                />
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container>
                        <Grid item xs={matches ? 4 : 12}>
                            <TextField
                                autoComplete="off"
                                error={passwords.errorVerify !== ''}
                                fullWidth
                                helperText={passwords.errorVerify}
                                label="Verificar contraseña"
                                name="verify"
                                type={passwords.showVerify ? 'text' : 'password'}
                                value={passwords.verify}
                                variant={matches ? "outlined" : "standard"}
                                onChange={(e) => onUpdateFieldPassword(e.target.name, e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Mostrar contraseña" placement="top-start" arrow>
                                                <IconButton 
                                                    className={passwords.showVerify ? "fas fa-eye" : "fas fa-eye-slash"} 
                                                    onClick={() => onShowPassword('showVerify')}
                                                    style={{ fontSize: '1rem' }}
                                                />
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            <Divider className={classes.divider} />
            <div className={classes.footer}>
                <Button className={classes.button} color="primary" size="small" variant="contained" onClick={() => btnChangePassword()}>
                    Cambiar
                </Button>
                <Button className={classes.button} size="small" variant="contained" onClick={() => resetTextPassword()}>
                    Cancelar
                </Button>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    passwords: state.profile.passwords
});
  
export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({...actionCreators}, dispatch)
)(ProfilePassword);