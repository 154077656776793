import {
    CANCEL_TEXT_PASSWORD,
    CHANGE_IMAGE,
    REMOVE_IMAGE,
    SET_ENTITY,
    SET_ERROR_FORM,
    SET_ERROR_PASSWORD,
    SET_FORM,
    SET_MASK,
    SET_NOT_FOUND,
    SHOW_PASSWORD,
    UPDATE_FIELD_FORM,
    UPDATE_FIELD_PASSWORD
} from './types';

const initialState = {
    loading: false,
    notFound: false,
    entity: {
        usuarioId: '',
        nombreUsuario: '',
        nombre: '',
        correo: '',
        telefono: '',
        imagen: '',
        empresas: [],
        roles: []
    },
    errors: {
        nombre: '',
        correo: ''
    },
    form: {
        nombre: '',
        correo: '',
        telefono: '',
        imagen: '',
        archivo: ''
    },
    passwords: {
        current: '',
        new: '',
        verify: '',
        error: '',
        errorNew: '',
        errorVerify: '',
        show: false,
        showNew: false,
        showVerify: false
    }
}

export const profileReducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case CANCEL_TEXT_PASSWORD:
            return {
                ...state,
                passwords: {
                    ...initialState.passwords
                }
            };
        case CHANGE_IMAGE:
            return {
                ...state,
                form: {
                    ...state.form,
                    archivo: action.file,
                    imagen: action.image
                }
            };
        case REMOVE_IMAGE:
            return {
                ...state,
                form: {
                    ...state.form,
                    archivo: '',
                    imagen: ''
                }
            };
        case SET_ENTITY:
            return {
                ...state,
                entity: {
                    ...state.entity,
                    usuarioId: action.payload.usuarioId,
                    nombreUsuario: action.payload.nombreUsuario,
                    nombre: action.payload.nombre,
                    correo: action.payload.correo,
                    telefono: action.payload.telefono,
                    imagen: action.payload.imagenUrl,
                    empresas: action.payload.empresas,
                    roles: action.payload.roles
                }
            };
        case SET_ERROR_FORM:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.key]: action.error
                }
            };
        case SET_ERROR_PASSWORD:
            return {
                ...state,
                passwords: {
                    ...state.passwords,
                    [action.key]: action.error
                }
            }
        case SET_FORM:
            return {
                ...state,
                form: {
                    nombre: state.entity.nombre,
                    correo: state.entity.correo,
                    telefono: state.entity.telefono,
                    imagen: state.entity.imagen,
                    archivo: ''
                }
            };
        case SET_MASK:
            return {
                ...state,
                loading: action.loading
            };
        case SET_NOT_FOUND:
            return {
                ...state,
                notFound: action.notFound
            };
        case SHOW_PASSWORD:
            return {
                ...state,
                passwords: {
                    ...state.passwords,
                    [action.key]: !state.passwords[action.key]
                }
            };
        case UPDATE_FIELD_FORM:
            return { 
                ...state,
                form: {
                    ...state.form,
                    [action.key]: action.value
                }
            };
        case UPDATE_FIELD_PASSWORD:
            return {
                ...state,
                passwords: {
                    ...state.passwords,
                    [action.key]: action.value
                }
            };
        default:
            return state;
    }

};