import React from 'react';
import clsx from 'clsx';
import { LinkContainer } from 'react-router-bootstrap';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Toolbar from '@material-ui/core/Toolbar';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/main/actions'; 

import UserCard from '../tools/UserCard';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
    appBar: {
        width: '100%',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: 150
        }),
        '@media (min-width: 701px)': {
            width: `calc(100% - 2.8rem)`
        },
        '@media (min-width: 1401px)': {
            width: `calc(100% - 3.5rem)`
        }
    },
    appBarShift: {
        width: '100%',
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: 150
        }),
        '@media (min-width: 701px)': {
            width: `calc(100% - 15rem)`,
        },
        '@media (min-width: 1401px)': {
            width: `calc(100% - 18rem)`,
        }
    },
    avatar: {
        height: '1.6rem',
        width: '1.6rem',
        '@media (min-width: 1401px)': {
            height: '2rem',
            width: '2rem',
        }
    },
    badge: {
        fontSize: '0.675rem',
        height: '1rem',
            minWidth: '1rem',
        padding: '0 0.2rem',
        '@media (min-width: 1401px)': {
            fontSize: '0.75rem',
            height: '1.25rem',
            minWidth: '1.25rem',
            padding: '0 0.25rem'
        }
    },
    botonIcon: {
        fontSize: '1rem',
        '@media (min-width: 1401px)': {
            fontSize: '1.25rem'
        }
    },
    contentAvatar: {
        alignItems: 'center',
        display: 'flex', 
        justifyContent: 'center'
    },
    contentText: {
        display: 'none',
        paddingRight: '.5rem', 
        textAlign: 'right',
        '@media (min-width: 701px)': {
            display: 'block'
        }
    },
    grow: {
        flexGrow: 1,
    },
    item: {
        fontFamily: 'inherit',
        minHeight: '1.5rem',
        paddingTop: 0,
        paddingBottom: 0,
        '&:hover': {
            backgroundColor: 'rgba(200, 200, 200, 0.2)'
        },
        '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        },
        '&.Mui-selected:hover': {
            backgroundColor: theme.palette.primary.main
        },
        '@media (min-width: 1401px)': {
            minHeight: '2rem'
        }
    },
    paper: {
        fontFamily: 'inherit',
        minWidth: '10rem',
        '@media (min-width: 1401px)': {
            minWidth: '12.5rem'
        }
    },
    text: {
        fontFamily: 'inherit',
        fontSize: '0.6875rem',
        '@media (min-width: 1401px)': {
            fontSize: '0.8125rem'
        }
    },
    textEmpresa: {
        fontSize: '.625rem', 
        fontWeight: 600, 
        lineHeight: 'normal', 
        maxWidth: '10rem', 
        overflow: 'hidden', 
        textOverflow: 'ellipsis', 
        textTransform: 'uppercase', 
        whiteSpace: 'nowrap',
        '@media (min-width: 1401px)': {
            fontSize: '.8125rem'
        }
    },
    textUsuario: {
        fontSize: '.75rem', 
        lineHeight: 'normal', 
        maxWidth: '10rem', 
        overflow: 'hidden', 
        textOverflow: 'ellipsis', 
        textTransform: 'lowercase', 
        whiteSpace: 'nowrap',
        '@media (min-width: 1401px)': {
            fontSize: '0.8125rem'
        }
    },
    toggleGroup: {
        backgroundColor: theme.palette.primary.main
    },
    toggle: {
        border: 'none',
        color: 'inherit',
        height: '2.25rem',
        minWidth: '2.25rem',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08);'
        },
        '&.Mui-selected': {
            backgroundColor: '#fff',
            borderRadius: 0,
            color: theme.palette.primary.main
        },
        '&.Mui-selected:hover': {
            backgroundColor: '#fff',
            color: theme.palette.primary.main
        },
        '@media (min-width: 1401px)': {
            height: '2.75rem',
            minWidth: '2.75rem'
        }
    },
    toggleSelect: {
        backgroundColor: '#fff',
        borderRadius: 0,
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: '#fff'
        }
    },
    toolbar: {
        minHeight: '2.25rem',
        paddingLeft: '0.25rem',
        paddingRight: '0.25rem',
        '@media (min-width: 1401px)': {
            minHeight: '2.75rem'
        }
    }
}));

const ForwardNavLink = React.forwardRef((props, ref) => (
    <LinkContainer {...props} innerRef={ref}>
        {props.children}
    </LinkContainer>
));

const TopBar = ({ avatar, navs, nombreComercial, open, tool, totalNotifications, usuario, viewType, fnToggleOpen, search, setTool }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [tarjetaEl, setTarjetaEl] = React.useState(null);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }
    
    function handleClose() {
        setAnchorEl(null);
    }

    function handleTarjetaClick(event) {
        setTarjetaEl(event.currentTarget);
    }

    function handleTarjetaClose() {
        setTarjetaEl(null);
    }

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
            })}
            color="primary"
        >
            <Toolbar className={classes.toolbar}>
                <Button className={classes.toggle} onClick={fnToggleOpen}>
                    <Icon className={clsx(classes.botonIcon, "fas fa-bars")}/>
                </Button>
                <Button aria-controls="navigation" aria-haspopup="true" className={classes.toggle} onClick={handleClick}>
                    <Icon className={clsx(classes.botonIcon, "far fa-window-restore")}/>
                </Button>
                <Menu
                    classes={{
                        paper: classes.paper
                    }}
                    elevation={1}
                    getContentAnchorEl={null}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    id="navigation"
                    keepMounted
                    onClose={handleClose}
                    open={Boolean(anchorEl)}
                >
                    {navs.map((item, key) => (
                        <ForwardNavLink key={key} to={item.reactivo ? `${item.path}${search}` : `/app${search}#view=${item.viewType}`}>
                            <MenuItem className={classes.item}  key={key} selected={item.viewType === viewType} onClick={() => setAnchorEl(null)}>
                                <ListItemText classes={{ primary: classes.text }} primary={item.name}/>
                            </MenuItem>
                        </ForwardNavLink>
                    ))}
                </Menu>
                <div className={classes.grow} />
                <Button className={clsx(classes.toggle, { [classes.toggleSelect]: (tool === 'applications') })} onClick={() => setTool(tool === 'applications' ? null : 'applications')} >
                    <Icon className={clsx(classes.botonIcon, "fas fa-th")}/>
                </Button>
                <Button className={clsx(classes.toggle, { [classes.toggleSelect]: (tool === 'companies') })} onClick={() => setTool(tool === 'companies' ? null : 'companies')} >
                    <Icon className={clsx(classes.botonIcon, "fas fa-building")}/>
                </Button>
                <Button className={clsx(classes.toggle, { [classes.toggleSelect]: (tool === 'notify') })} onClick={() => setTool(tool === 'notify' ? null : 'notify')} >
                    <Badge classes={{badge: classes.badge}} badgeContent={totalNotifications}  color="secondary" overlap="rectangle" >
                        <Icon className={clsx(classes.botonIcon, "fas fa-bell")}/>
                    </Badge>
                </Button>
                <Button className={clsx(classes.toggle, { [classes.toggleSelect]: (tool === 'settings') })} onClick={() => setTool(tool === 'settings' ? null : 'settings')} >
                    <Icon className={clsx(classes.botonIcon, "fas fa-cog")}/>
                </Button>
                <Button aria-controls="tarjeta" className={classes.toggle} onClick={handleTarjetaClick}>
                    <div className={classes.contentAvatar}>
                        <div className={classes.contentText}>
                            <div className={classes.textUsuario}>{usuario}</div>
                            <div className={classes.textEmpresa}>{nombreComercial}</div>
                        </div>
                        {!avatar && (<Icon className={clsx(classes.botonIcon, "fas fa-user-circle")}/>)}
                        {avatar && (<Avatar src={`data:image/jpeg;base64,${avatar}`} className={classes.avatar}>JM</Avatar>)}
                    </div>
                </Button>
                <Popover
                    id="tarjeta"
                    anchorEl={tarjetaEl}
                    keepMounted
                    open={Boolean(tarjetaEl)}
                    onClose={handleTarjetaClose}
                    anchorOrigin={{
                        horizontal: 'center',
                        vertical: 'bottom'
                    }}
                    transformOrigin={{
                        horizontal: 'center',
                        vertical: 'top'
                    }}
                >
                    <UserCard onHandleClose={handleTarjetaClose}/>
                </Popover>
            </Toolbar>
        </AppBar>
    )
}

const mapStateToProps = state => ({
    avatar: state.session.user.avatar,
    navs: state.main.navs,
    nombreComercial: state.session.company.nombreComercial,
    search: state.router.location.search,
    totalNotifications: state.notify.total,
    usuario: state.session.user.usuario,
    viewType: state.main.viewType
});
  
export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(TopBar);