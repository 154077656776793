import React,  { useEffect, useState } from 'react';
import axios from 'axios';

import CircularProgress from '@material-ui/core/CircularProgress';
import Header from './components/main/Header';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { bindActionCreators } from 'redux';
import { connect, useStore, } from 'react-redux';
import * as actionCreators from './store/main/actions';

import { IdentityUrl } from './config';
import Error from './pages/Error';
import Unauthorized from './pages/Unauthorized';
import { injectAsyncReducers } from './store/configureStore';

const useStyles = makeStyles(theme => ({
    root: {
        alignItems: 'center',
        color: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'center',
        height: 'calc(100% - 4px)'
    },
    view: {
        backgroundColor: theme.palette.primary.main + '14', 
        boxSizing: 'border-box',
        flexDirection: 'column',
        display: 'flex',
        height: '100%',
        position: 'relative'
    }
}));

const MicroFrontend = ({ component, history, host, name, navs, theme, view, 
        addFavorite, addOptionNav, closeOption, dropFavorite, setOption, updateOptionNav }) => {

    const classes = useStyles();
    const store = useStore();
    const [access, setAccess] = useState({});
    const [authorized, setAuthorized] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState('');
    
    useEffect(() => {
        checkAccess();
        
        return () => {
            unloadMicroFrontend();
        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!component && authorized && !loading && error === '') {
            renderMicroFrontend();
        }
    // eslint-disable-next-line
    }, [loading]);

    async function checkAccess() {
        try {
            let viewType = view; //despues agregaroperaciones substring cuando venga con pametros
            
            if(navs.filter(n => n.viewType === viewType)[0]) {
                let accesos = {};
                navs.filter(n => n.viewType === viewType)[0].access.forEach(e => {
                    accesos[e.Nombre] = true;
                });

                setAccess(accesos);
                setAuthorized(true);

                if(component) {
                    setLoading(false);
                }
                else {
                    await loadMicroFrontend();
                }
                setOption(viewType);
                updateOptionNav(viewType, window.location.pathname, navs.filter(n => n.viewType === viewType)[0].favorite);
            }
            else {
                let url = `${IdentityUrl}/api/v1/modulos/opciones/acceso?viewType=${viewType}`;
                let response = await axios.get(url);
                let data = response.data;

                if(data.status === 400) {
                    setLoading(false);
                    setError('No se encontro la opción solicitada, comuniquese con el administrador.');
                }
                else if(data.status === 401) {
                    setLoading(false);
                }
                else {
                    let icon = data.option.icon || ' ';
                    addOptionNav(data.option.name, viewType, false, 'fas ' + icon.split(" ")[1], window.location.pathname, data.option.access, data.option.optionId, data.option.favorite);
                    let accesos = {};
                    data.option.access.forEach(e => {
                        accesos[e.Nombre] = true;
                    });

                    setAccess(accesos);
                    setAuthorized(true);
                    
                    if(component) {
                        setLoading(false);
                    }
                    else {
                        await loadMicroFrontend();
                    }
                }
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            setError('No se pudo verificar los accesos.');
        }
    }

    async function loadMicroFrontend() {
        const scriptId = `micro-frontend-script-${name}`;

        if (document.getElementById(scriptId)) {
            setLoading(false);
        }
        else {
            try {
                let url = `${host}/asset-manifest.json`;
                let response = await fetch(url);
                let manifest = await response.json();
                
                const script = document.createElement('script');
                script.id = scriptId;
                script.crossOrigin = '';
                script.src = `${new URL(host).origin}${manifest['files']['main.js']}`;
                script.onload = () => {
                    setLoading(false);
                };
                document.head.appendChild(script);
            } catch (error) {
                console.log(error);
                setError('No se pudo conectar con el servidor del módulo.');
                setLoading(false);
            }
        }
    }

    function unloadMicroFrontend() {
        if(!component && typeof window[`unmount${name}`] === 'function') {
            window[`unmount${name}`](`${name}-container`);
        }
    }

    function renderMicroFrontend() {
        window[`render${name}`](`${name}-container`, history, theme, closeOption, setTitle, store, injectAsyncReducers, access);
    }

    function handleClick() {
        if(navs.filter(n => n.viewType === view)[0]) {
            if(!navs.filter(n => n.viewType === view)[0].favorite) {
                addFavorite(navs.filter(n => n.viewType === view)[0].optionId, view, navs.filter(n => n.viewType === view)[0].path);
            }
            else {
                dropFavorite(navs.filter(n => n.viewType === view)[0].optionId, view, navs.filter(n => n.viewType === view)[0].path);
            }
        }
    }
    
    if(loading && !navs.filter(n => n.viewType === view)[0]) {
        return(
            <div className={classes.root} >
                <div style={{ textAlign: 'center' }}><CircularProgress color="primary"/>
                    <p>Cargando formulario, espere por favor.</p>
                </div>
            </div>
        )
    }

    if(error !== '') {
        return <Error error={error} />
    }

    if(!authorized && !navs.filter(n => n.viewType === view)[0]) {
        return <Unauthorized />
    }

    if(component && navs.filter(n => n.viewType === view)[0]) {
        const Component = component;

        return (
            <div className={classes.view}>
                <Header
                    favorite={navs.filter(n => n.viewType === view)[0].favorite}
                    favoriteEnabled 
                    iconCls={navs.filter(n => n.viewType === view)[0].icon} 
                    subtitle={navs.filter(n => n.viewType === view)[0].name} 
                    title={title}
                    onClick={handleClick}
                />
                <Component access={access} onClose={closeOption} setTitle={setTitle} />
            </div>
        )
    }

    return (
        <div className={classes.view}>
            <Header
                favorite={navs.filter(n => n.viewType === view)[0].favorite}
                favoriteEnabled
                iconCls={navs.filter(n => n.viewType === view)[0].icon} 
                subtitle={navs.filter(n => n.viewType === view)[0].name} 
                title={title}
                onClick={handleClick}
            />
            <main id={`${name}-container`} style={{ display: 'flex', flexGrow: 1 }} />
        </div>
    )
}

const mapStateToProps = state => ({
    navs: state.main.navs,
    viewType: state.main.viewType
});
  
export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(MicroFrontend);