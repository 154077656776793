import React from 'react';
import clsx from 'clsx';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/main/actions';

const useStyles = makeStyles(() => ({
    circle: {
        height: '2rem',
        marginLeft: '0.05rem',
        marginRight: '0.7rem',
        marginTop: '0.4rem',
        padding: 0,
        width: '2rem',
        '@media (min-width: 1401px)': {
            height: '2.5rem',
            marginLeft: '0.0625rem',
            marginRight: '0.875rem',
            marginTop: '0.5rem',
            width: '2.5rem'
        }
    },
    content: {
        flex: '1 1 auto',
        overflow: 'auto'
    },
    iconButton: {
        color: '#fff',
        fontSize: '1.2rem',
        '@media (min-width: 1401px)': {
            fontSize: '1.5rem'
        }
    },
    panelIn: {
        borderRadius: 0,
        boxShadow: '0 8px 8px 0 rgba(0,0,0,.16)',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 2.2rem)',
        minWidth: '17.5rem',
        paddingLeft: '1.2rem',
        paddingRight: '1.2rem',
        position: 'absolute',
        right: 0,
        top: '2.25rem',
        width: '17.5rem',
        zIndex: 1201,
        '@media (min-width: 1401px)': {
            height: 'calc(100% - 2.75rem)',
            minWidth: '21.875rem',
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
            top: '2.75rem',
            width: '21.875rem'
        }
    },
    subtitle: {
        color: '#595950',
        fontFamily: 'inherit',
        fontSize: '0.7rem',
        fontWeight: '600',
        lineHeight: '1.8rem',
        '@media (min-width: 1401px)': {
            fontSize: '0.875rem',
            lineHeight: '2.25rem'
        }
    },
    title: {
        fontSize: '0.8rem',
        fontWeight: 600,
        marginBottom: '1rem',
        marginTop: '1rem',
        '@media (min-width: 1401px)': {
            fontSize: '1rem',
            marginBottom: '1.25rem',
            marginTop: '1.25rem'
        }
    }
}));

const colors = [{ name: 'blue', title: 'Azul', value: '#1976D2' }, 
    { name: 'gray', title: 'Gris', value: '#404040' },
    { name: 'green', title: 'Verde', value: '#388E3C' },
    { name: 'orange', title: 'Orange', value: '#f06c00' },
    { name: 'purple', title: 'Morado', value: '#7B1FA2' },
    { name: 'red', title: 'Rojo', value: '#D32F2F' },
    { name: 'teal', title: 'Teal', value: '#00796B' },
    { name: 'yellow', title: 'Amarillo', value: '#E9B01C'}]

const Setting = ({ color, themeChange }) => {
    const classes = useStyles();

    return (
        <Paper className={classes.panelIn}>
            <div className={classes.title}>Configuración de la aplicación</div>
            <div className={classes.content}>
                <div className={classes.subtitle}>Elegir un tema</div>
                {colors.map((item,key) => (
                    <Tooltip key={key} title={item.title}>
                        <IconButton className={classes.circle} style={{ backgroundColor: item.value}} onClick={() => themeChange(item.name)}>
                            {item.name === color && (<Icon className={clsx(classes.iconButton, "fas fa-check")} />)}
                        </IconButton>
                    </Tooltip>
                ))}
            </div>
        </Paper>
    )
}

const mapStateToProps = state => ({
    color: state.main.color
});
  
export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Setting);