import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const getOverrides = (main, light) => { 
    return {
        MuiAccordionDetails: {
            root: {
                padding: 0
            }
        },
        MuiAccordionSummary: {
            content: {
                margin: 0,
                "&.Mui-expanded": {
                    margin: 0
                }
            },
            root: {
                minHeight: '1.75rem',
                padding: '0 0.75rem 0 0.75rem',
                "&.Mui-expanded": {
                    minHeight: '1.75rem'
                }
            }
        },
        MuiButton: {
            containedSizeSmall: {
                fontSize: 0,
                padding: '.125rem 0.5rem .0625rem',
                '@media (min-width: 701px)': {
                    fontSize: '.8125rem'
                }
            },
            iconSizeSmall: {
                '& > *:first-child': {
                    fontSize: '.875rem',
                    width: 'auto'
                }
            },
            root: {
                minHeight: '1.5625rem',
                minWidth: '3rem',
                textTransform: 'none',
                '@media (min-width: 701px)': {
                    minWidth: '4rem'
                }
            },
            startIcon: {
                marginRight: 0,
                '&.MuiButton-iconSizeSmall': {
                    marginLeft: 0
                },
                '@media (min-width: 701px)': {
                    marginRight: '.5rem',
                    '&.MuiButton-iconSizeSmall': {
                        marginLeft: '-.125rem'
                    }
                }
            }
        },
        MuiChip: {
            root: {
                fontFamily: 'inherit',
                fontSize: '0.75rem'
            }
        },
        MuiFab: {
            sizeSmall: {
                height: '2.25rem',
                width: '2.25rem'
            }
        },
        MuiFormHelperText: {
            contained: {
                marginLeft: '0.5rem',
                marginRight: '0.5rem',
                marginTop: 0
            },
            root: {
                fontFamily: 'inherit',
                fontSize: '0.6875rem'
            }
        },
        MuiFormLabel: {
            root: {
                fontFamily: 'inherit',
                fontSize: '0.8125rem'
            }
        },
        MuiGrid: {
            'spacing-xs-1': {
                '& > .MuiGrid-item': {
                    padding: 5
                }
            }
        },
        MuiIconButton: {
            root: {
                padding: 4
            }
        },
        MuiInput: {
            formControl: {
                "label + &": {
                    marginTop: "12px"
                }
            }
        },
        MuiInputBase:{
            input: {
                height: 'auto',
                padding: '0.3125rem 0 0.3125rem'
            },
            root: {
                fontFamily: 'inherit',
                fontSize: '0.75rem'
            }
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(0.375rem, 0.3125rem) scale(1)',
                '&.MuiInputLabel-shrink': {
                    transform: 'translate(0.875rem, -0.375rem) scale(0.7)'
                }
            },
            formControl: {
                transform: 'translate(0, 1.25rem) scale(1)'
            }
        },
        MuiMenuItem: {
            root: {
                fontFamily: 'inherit',
                fontSize: '0.875rem'
            }
        },
        MuiOutlinedInput: {
            adornedEnd: {
                paddingRight: 4
            },
            input: {
                padding: '0.3125rem 0.375rem'
            },
            multiline: {
                padding: '0.3125rem 0.375rem'
            },
            root: {
                backgroundColor: '#fff'
            }
        },
        MuiSvgIcon: {
            root: {
                fontSize: '1.125rem'
            }
        },
        MuiTab: {
            labelIcon: {
                minHeight: '4rem',
                '& .MuiTab-wrapper > *:first-child': {
                    fontSize: '1.125rem',
                    marginBottom: '0.25rem',
                    width: 'auto',
                    '@media (min-width: 701px)': {
                        fontSize: '1.25rem'
                    }
                }
            },
            root: {
                fontFamily: 'inherit',
                fontSize: '0.75rem',
                textTransform: 'initial'
            }
        },
        MuiTableCell: {
            head: {
                fontSize: '0.75rem',
                fontWeight: 400
            },
            root: {
                fontFamily: 'inherit',
                fontSize: '0.6875rem',
                "& < .Mui-selected": {
                    color: '#fff'
                }
            },
            sizeSmall: {
                "&.MuiTableCell-paddingCheckbox": {
                    padding: '0px 12px 0 12px'
                }
            },
            stickyHeader: {
                backgroundColor: '#fff'
            }
        },
        MuiTablePagination: {
            toolbar: {
                fontFamily: 'inherit',
                fontSize: '0.75rem',
                minHeight: '2.25rem',
                paddingLeft: 0
            }
        },
        MuiTableRow: {
            root: {
                "&.Mui-selected": {
                    backgroundColor: main + ' !important'
                },
                "&.MuiTableRow-hover:hover": {
                    backgroundColor: light
                },
                "&.Mui-selected > td.MuiTableCell-root": {
                    color: 'inherit'
                }
            }
        },
        MuiTypography: {
            body2: {
                fontFamily: 'inherit',
                fontSize: '0.75rem'
            }
        }
    }
}

const themes = {
    'blue': createMuiTheme({
        palette: {
            primary: {
                main: '#1976d2',
                light: '#e3f2fd'
            }
        },
        overrides: getOverrides('#1976d2', '#e3f2fd')
    }),
    'gray': createMuiTheme({
        palette: {
            primary: {
                main: '#404040',
                light: '#f4f4f4'
            }
        },
        overrides: getOverrides('#404040', '#f4f4f4')
    }),
    'green': createMuiTheme({
        palette: {
            primary: {
                main: '#388e3c',
                light: '#e8f5e9'
            }
        },
        overrides: getOverrides('#388e3c', '#e8f5e9')
    }),
    'orange': createMuiTheme({
        palette: {
            primary: {
                main: '#f06c00',
                light: '#fff3e0'
            }
        },
        overrides: getOverrides('#f06c00', '#fff3e0')
    }),
    'purple': createMuiTheme({
        palette: {
            primary: {
                main: '#7b1fa2',
                light: '#f3e5f5'
            }
        },
        overrides: getOverrides('#7b1fa2', '#f3e5f5')
    }),
    'red': createMuiTheme({
        palette: {
            primary: {
                main: '#d32f2f',
                light: '#ffebee'
            }
        },
        overrides: getOverrides('#d32f2f', '#ffebee')
    }),
    'teal': createMuiTheme({
        palette: {
            primary: {
                main: '#00796b',
                light: '#e0f2f1'
            }
        },
        overrides: getOverrides('#00796b', '#e0f2f1')
    }),
    'yellow': createMuiTheme({
        palette: {
            primary: {
                main: '#e9b01c',
                light: '#fff9e8'
            }
        },
        overrides: getOverrides('#e9b01c', '#fff9e8')
    })
}

export const crearTema = (color) => {
    switch (color) {
        case 'blue':
            return themes.blue
        case 'gray':
            return themes.gray;
        case 'green':
            return themes.green;
        case 'purple':
            return themes.purple;
        case 'red':
            return themes.red;
        case 'teal':
            return themes.teal;
        case 'yellow':
            return themes.yellow;
        default:
            return themes.orange
    }
}