import React from 'react';

import Icon from '@material-ui/core/Icon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ProfileForm from './ProfileForm';
import ProfilePassword from './ProfilePassword';

const useStyles = makeStyles(theme => ({
    tabsHorizontal: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    tabsVertical: {
        borderRight: `1px solid ${theme.palette.divider}`,
    }
}));

const ProfileDetails = () => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:701px)');
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Paper>
            <div style={{ display: 'flex', flexDirection: matches ? "row" : "column" }}>
                <Tabs
                    orientation={matches ? "vertical" : "horizontal"}
                    variant="fullWidth"
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon label tabs example"
                    className={matches ? classes.tabsVertical : classes.tabsHorizontal}
                >
                    <Tab icon={<Icon className="fas fa-user-circle" />} label="Cuenta" />
                    <Tab icon={<Icon className="fas fa-user-shield" />} label="Seguridad" />
                </Tabs>
                {value === 0 && (<ProfileForm />)}
                {value === 1 && (<ProfilePassword />)}
            </div>
        </Paper>
    );
}

export default ProfileDetails;