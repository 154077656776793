import {
    ADD_NOTIFICATION,
    RECEIVE_NOTIFICATIONS,
    RECEIVE_POPUPS,
    REMOVE_NOTIFICATION,
    REMOVE_POPUP,
    REQUEST_NOTIFICATIONS,
    SET_TOTAL_NO_READ
} from './types';

const initialState = {
    isLoad: false,
    total: 0,
    notifications: [],
    popups: []
};

export const notifyReducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case ADD_NOTIFICATION:
            return {
                ...state,
                notifications: [action.payload, ...state.notifications]
            };
        case RECEIVE_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload,
                isLoad: true
            };
        case RECEIVE_POPUPS:
            return {
                ...state,
                popups: action.payload
            };
        case REMOVE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter(item => item.notificacionId !== action.payload)
            };
        case REMOVE_POPUP:
            return {
                ...state,
                popups: state.popups.filter(item => item.notificacionId !== action.payload)
            };
        case REQUEST_NOTIFICATIONS:
            return {
                ...state,
                isLoad: false,
                notifications: []
            };
        case SET_TOTAL_NO_READ:
            return {
                ...state,
                total: action.payload
            };
        default:
            return state;
    }

};