export const CANCEL_TEXT_PASSWORD = 'profile/cancel-text-password';
export const CHANGE_IMAGE = 'profile/change-image';
export const REMOVE_IMAGE = 'profile/remove-image';
export const SET_ENTITY = 'profile/set-entity';
export const SET_ERROR_FORM = 'profile/set-error';
export const SET_ERROR_PASSWORD = 'profile/set-error-password';
export const SET_FORM = 'profile/set-form';
export const SET_MASK = 'profile/set-mask';
export const SET_NOT_FOUND = 'profile/set-not-found';
export const SHOW_PASSWORD = 'profile/show-password';
export const UPDATE_FIELD_FORM = 'profile/update-field-form';
export const UPDATE_FIELD_PASSWORD = 'profile/update-field-password';
