import 'react-notifications-component/dist/theme.css';
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';
import ReactNotification, { store } from 'react-notifications-component';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Slide from '@material-ui/core/Slide';

import { crearTema } from './helpers/temas';
import AdvancedSearch from './components/search/AdvancedSearch';
import localRoutes from './routes/localRoutes';
import MicroFrontend from './MicroFrontend';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Notifications from './pages/Notifications';
import Loading from './components/main/Loading';
import FloatingBar from './components/main/FloatingBar';
import SideBar from './components/main/SideBar';
import TopBar from './components/main/TopBar';
import Dummy from './pages/Dummy';
import Sencha from './pages/Sencha';
import Company from './components/tools/Company';
import Module from './components/tools/Module';
import MessageBox from './components/messagebox/MessageBox';
import Notify from './components/tools/Notify';
import Setting from './components/tools/Setting';
import Profile from './pages/Profile';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from './store/main/actions';
import * as notifyCreators from './store/notify/actions';
import * as sessionCreators from './store/session/actions';

const useStyles = makeStyles((theme) => ({
    component: {
      height: 'calc(100% - 2.25rem)',
      paddingLeft: 0,
      transition: theme.transitions.create(['width', 'padding'], {
        easing: theme.transitions.easing.sharp,
        duration: 150
      }),
      '@media (min-width: 600px)': {
        paddingLeft: `2.8rem`
      },
      '@media (min-width: 1401px)': {
        height: 'calc(100% - 2.75rem)',
        paddingLeft: '3.5rem'
      }
    },
    componentShift: {
      paddingLeft: 0,
      transition: theme.transitions.create(['width', 'padding'], {
        easing: theme.transitions.easing.sharp,
        duration: 150
      }),
      '@media (min-width: 701px)': {
        paddingLeft: `15rem`
      },
      '@media (min-width: 1401px)': {
        paddingLeft: `18rem`
      }
    },
    content: {
        width: '100%',
        height: '100%'
    },
    root: {
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    toolbar: {
      minHeight: '2.25rem',
      '@media (min-width: 1401px)': {
        minHeight: '2.75rem'
      }
    }
}));

const App = ({ color, company, dialog, popups, remoteRoutes, resources, search, totalLoad, 
    addNotification, createInterceptor, getPopups, getSession, getTotalNoRead, removePopup, pathname }) => {
  
  const classes = useStyles();
  const theme = crearTema(color);
  const [open, setOpen] = React.useState(true);
  const [tool, setTool] = React.useState(null);

  useEffect(() => {
    createInterceptor();
    getSession();
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(totalLoad >= 2) {
      getPopups();
      getTotalNoRead(showNotifications);
      connect();  
    }
  // eslint-disable-next-line
  }, [totalLoad]);

  async function connect() {
    let connection = new HubConnectionBuilder()
      .withUrl(`${resources.uriSvcNotifyApi}/notificacion`, HttpTransportType.WebSockets)
      .build();

    connection.on("RecibirNotificacion", (notificacionId, dTipoAlerta, titulo, contenido, fecha, enlace, enlaceExterno, iconoCls, emisor) => {
        showNotifications(dTipoAlerta, titulo, contenido);
        if(notificacionId !== 0) {
          addNotification(notificacionId, dTipoAlerta, titulo, contenido, fecha, enlace, enlaceExterno, iconoCls, emisor);
        }
    });
    
    connection.start().then(r => console.log('Conectado')).catch(err => console.error(err.toString()));

    connection.onclose(async () => {
      console.log('conexión interrumpida');
      await start(connection);
    });
  }

  async function start(connection) {
    try {
        await connection.start();
        console.log("reconectado");
    } catch (err) {
        console.log(err);
        setTimeout(() => start(connection), 5000);
    }
}

  function handleDrawerToggle() {
    setOpen(!open);
  }

  function showNotifications(dTipoAlerta, title, message) {
    store.addNotification({
      title: title,
      message: message,
      type: dTipoAlerta,
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      width: 350,
      dismiss: {
        click: false,
        duration: 5000,
        pauseOnHover: true,
        showIcon: true
      }
    });
  }

  const handleClosePopup = (notificacionId) => {
    removePopup(notificacionId);
  };

  if(totalLoad < 2) {
    return (
      <MuiThemeProvider theme={theme}>
        <Loading />
      </MuiThemeProvider>
    )
  }

  return (
    <MuiThemeProvider theme={theme}>
      <ReactNotification />
      <div className={classes.root}>
        <FloatingBar open={open} fnToggleOpen={handleDrawerToggle}/>
        <SideBar open={open} setOpen={setOpen}/>
        <TopBar open={open} tool={tool} fnToggleOpen={handleDrawerToggle} setTool={setTool}/>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div id="main-container" className={clsx(classes.component, {
                [classes.componentShift]: open
            })}>
            <Switch>
              {remoteRoutes.map((r, key) => {

                return r.ruta.split(';').filter(f => f !== '').map((path, index) => {
                  return (
                    <Route
                      exact
                      path={path}
                      key={`r-${key}-${index}`}
                      render={props => <MicroFrontend history={props.history} host={r.host} name={r.nombreHost} path={path} theme={theme} view={r.viewType}/>}
                    />
                  )
                });
              })}
              {Object.keys(localRoutes).map((route, key) => {
                const paths = localRoutes[route].paths;
                const component = localRoutes[route].component;
                const viewType = localRoutes[route].viewType;

                return paths.map((path, index) => {
                  return (
                    <Route 
                      exact
                      path={path}
                      key={`${key}-${index}`}
                      render={() => <MicroFrontend component={component} path={path} view={viewType}/>}
                    />
                  );
                });
              })}
              <Route exact path="/" component={Home} />
              <Route exact path="/app" component={Dummy} />
              <Route exact path="/notificaciones" component={Notifications} />
              <Route exact path="/perfil" component={Profile} />
              <Route exact path="/perfil/:id" component={Profile} />
              <Route component={NotFound} />
            </Switch>
            <div id="container-app" 
              style={pathname === '/app/' || pathname === '/app' ? { visibility: 'visible', position: 'fixed', height: '100%', width: '100%' } : { visibility: 'hidden', height: 0 }}>
              <Sencha />
            </div>
          </div>
        </main>
        <Slide direction="left" in={tool === 'companies'} timeout={0} mountOnEnter unmountOnExit>
          <div><Company /></div>
        </Slide>
        <Slide direction="right" in={tool === 'applications'} timeout={0} mountOnEnter={false} unmountOnExit={false}>
          <div><Module setOpen={setOpen} setTool={setTool}/></div>
        </Slide>
        <Slide direction="right" in={tool === 'notify'} timeout={0} mountOnEnter unmountOnExit>
          <div><Notify /></div>
        </Slide>
        <Slide direction="right" in={tool === 'settings'} timeout={0} mountOnEnter unmountOnExit>
          <div><Setting /></div>
        </Slide>
      </div>
      {search.open && (<AdvancedSearch
        autoLoad={search.autoLoad}
        fields={search.fields}
        data={search.data}
        multiSelect={search.multiSelect}
        open={search.open}
        paginated={search.paginated}
        remote={search.remote}
        title={search.title}
        fnCallback={search.callback}
        getData={search.getData}
      />)}
      <MessageBox
        button={dialog.button}
        icon={dialog.icon}
        message={dialog.message}
        open={dialog.open}
        title={company.nombreComercial}
        fnCallback={dialog.callback}
      />
      {popups.map((item, key) => (<Dialog key={key} open={true} onClose={() => handleClosePopup(item.notificacionId)}>
        <DialogTitle>{item.titulo}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {item.contenido}
          </DialogContentText>
        </DialogContent>
      </Dialog>))}
    </MuiThemeProvider>
  )
}

const mapStateToProps = state => ({
  color: state.main.color,
  company: state.session.company,
  dialog: state.main.dialog,
  pathname: state.router.location.pathname,
  nroNotications: state.notify.total,
  popups: state.notify.popups,
  remoteRoutes: state.main.remoteRoutes,
  resources: state.main.resources,
  search: state.main.advancedSearch,
  totalLoad: state.main.totalLoad
});

export default connect(
  mapStateToProps,
  dispatch => bindActionCreators({...actionCreators, ...notifyCreators, ...sessionCreators}, dispatch)
)(App);
