import React, { useEffect } from 'react';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { LinkContainer } from 'react-router-bootstrap';
import IconModulo from '../../icons/icon';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/main/actions';

const useStyles = makeStyles(theme => ({
    contenido: {
        flex: '1 1 auto',
        overflow: 'auto'
    },
    icono: {
        color: theme.palette.primary.main,
        height: '2.4rem',
        '@media (min-width: 1401px)': {
            height: '3rem'
        }
    },
    item: {
        textAlign: 'center',
        maxHeight: '6.5rem',
        '@media (min-width: 1401px)': {
            maxHeight: '8.125rem',
        }
    },
    lista: {
        width: '100%'
    },
    panelIn: {
        borderRadius: 0,
        boxShadow: '0 8px 8px 0 rgba(0,0,0,.16)',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 2.2rem)',
        minWidth: '17.5rem',
        paddingLeft: '1.2rem',
        paddingRight: '1.2rem',
        position: 'absolute',
        right: 0,
        top: '2.25rem',
        width: '17.5rem',
        zIndex: 1201,
        '@media (min-width: 1401px)': {
            height: 'calc(100% - 2.75rem)',
            minWidth: '21.875rem',
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
            top: '2.75rem',
            width: '21.875rem'
        }
    },
    texto: {
        color: '#595950',
        fontFamily: 'inherit',
        fontSize: '0.688rem',
        fontWeight: 600,
        padding: '0 0.7rem',
        '@media (min-width: 1401px)': {
            fontSize: '0.86rem',
            padding: '0 0.875rem'
        }
    },
    title: {
        fontSize: '0.8rem',
        fontWeight: 600,
        marginBottom: '1rem',
        marginTop: '1rem',
        '@media (min-width: 1401px)': {
            fontSize: '1rem',
            marginBottom: '1.25rem',
            marginTop: '1.25rem'
        }
    }
}));

const Module = ({ hash, modulos, search, setModule, setOpen, setTool }) => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:701px)');

    useEffect(() => {
        setModule();
    // eslint-disable-next-line
    }, [search]);

    function handleClick() {
        setTool(null);
        if(!matches) {
            setOpen(false);
        }
    }

    function link(moduloId) {
        let params = new URLSearchParams(search);

        params.set('moduloId', moduloId);
        return { search: params.toString(), hash: hash };
    }

    return (
        <Paper className={classes.panelIn}>
            <div className={classes.title}>Tus Módulos</div>
            <div className={classes.contenido}>
                <GridList className={classes.lista}>
                    {modulos.map((item, key) => (
                        <GridListTile className={classes.item} key={key}>
                            <LinkContainer to={link(item.moduloId)}>
                                <IconButton onClick={handleClick}>
                                    <IconModulo className={classes.icono} name={item.imagen} />
                                </IconButton>
                            </LinkContainer>
                            <Typography className={classes.texto}>{item.nombre}</Typography>
                        </GridListTile>
                    ))}
                </GridList>
            </div>
        </Paper>
    )
}

const mapStateToProps = state => ({
    hash: state.router.location.hash,
    modulos: state.main.modulos,
    search: state.router.location.search,
});
  
export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Module);