import React from 'react';
import clsx from 'clsx';

import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main,
        fontSize: '1.6rem',
        height: '3.5rem', 
        width: '3.5rem',
        '@media (min-width: 701px)': {
            fontSize: '2rem',
            height: '4.5rem', 
            width: '4.5rem'
        }
    },
    center: {
        display: 'flex',
        alignItems: 'center'
    },
    details: {
        display: 'flex',
        padding: '0.5rem 1rem 1rem'
    },
    icon: {
        color: '#3C4858',
        fontSize: '0.875rem',
        paddingRight: '0.375rem'
    },
    label: {
        fontSize: '0.75rem',
        fontWeight: 500,
        '@media (min-width: 701px)': {
            fontSize: '0.875rem',
        }
    },
    paper: {
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem'
        }
    },
    text: {
        fontSize: '0.75rem',
        '@media (min-width: 701px)': {
            fontSize: '0.875rem',
        }
    },
    title: {
        display: 'flex',
        fontSize: '0.875rem',
        minHeight: '2rem',
        padding: '0 1rem 0 1rem'
    },
    titleContent: {
        alignItems: 'center',
        display: 'flex',
        margin: '0.5rem 0'
    }
}));

const ProfileSummary = ({ entity }) => {
    const classes = useStyles();
    const big = useMediaQuery('(min-width:1401px)');
    const middle = useMediaQuery('(min-width:701px)');

    return (
        <Paper className={classes.paper}>
            <div className={classes.title}>
                <div className={classes.titleContent}>
                    <Icon className={clsx("fas fa-id-card", classes.icon)}/>
                    <div>Resumen</div>
                </div>
            </div>
            <div className={classes.details}>
                <Grid container spacing={big || middle ? 2 : 1}>
                    <Grid className={classes.center} item>
                        <Avatar src={entity.imagen === '' ? '' : entity.imagen} className={classes.avatar}>
                            {entity.nombre.charAt(0).toUpperCase() + (entity.nombre.split(' ').length > 1 ? entity.nombre.split(' ')[1].charAt(0).toUpperCase() : '') }
                        </Avatar>
                    </Grid>
                    <Grid item spacing={1} xs>
                        <Grid container direction="column" item spacing={1} xs={big ? 3 : (middle ? 6 : 12)}>
                            <Grid container item justify="space-between">
                                <div className={classes.label}>Nombres:</div>
                                <div className={classes.text}>{entity.nombre}</div>
                            </Grid>
                            <Grid container item justify="space-between">
                                <div className={classes.label}>Usuario:</div>
                                <div className={classes.text}>{entity.nombreUsuario}</div>
                            </Grid>
                            <Grid container item justify="space-between">
                                <div className={classes.label}>Correo:</div>
                                <div className={classes.text}>{entity.correo}</div>
                            </Grid>
                            <Grid container item justify="space-between">
                                <div className={classes.label}>Teléfono:</div>
                                <div className={classes.text}>{entity.telefono}</div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Paper>
    );
}

const mapStateToProps = state => ({
    entity: state.profile.entity
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({}, dispatch)
)(ProfileSummary);