import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Typography from '@material-ui/core/Typography';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/main/actions';

import IconModulo from '../../icons/icon';
import TreeList from './TreeList';

const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: '#fff',
        height: '1.6rem',
        marginLeft: '0.6rem',
        marginRight: '0.6rem',
        width: '1.6rem',
        '@media (min-width: 1401px)': {
            height: '2rem',
            marginLeft: '0.75rem',
            marginRight: '0.75rem',
            width: '2rem'
        }
    },
    iconModule: {
        color: theme.palette.primary.main,
        height: '1.2rem',
        '@media (min-width: 1401px)': {
            height: '1.5rem',
        }
    },
    logo: {
        height: '1.6rem',
        marginLeft: '0.6rem',
        marginRight: '0.6rem',
        '@media (min-width: 1401px)': {
            height: '2rem',
            marginLeft: '0.75rem',
            marginRight: '0.75rem'
        }
    },
    module: {
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        height: '2.25rem',
        minHeight: '2.25rem',
        position: 'relative',
        textAlign: 'center',
        '&:after': {
            right: '0.75rem',
            width: 'calc(100% - 1.5rem)',
            bottom: 0,
            height: '0.0625rem',
            content: '""',
            position: 'absolute',
            backgroundColor: 'hsla(0,0%,100%,.3)',
            '@media (min-width: 1401px)': {
                right: '0.9375rem',
                width: 'calc(100% - 1.875rem)',
            }
        },
        '@media (min-width: 1401px)': {
            height: '2.75rem',
            minHeight: '2.75rem',
        }
    },
    paper: {
        background: 'url(/images/sidebar.jpg) no-repeat',
        backgroundSize: '100% 100%',
        width: '15rem',
        '@media (min-width: 1401px)': {
            width: '18rem'
        }
    },
    swipeable: {
        display: 'block',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        '@media (min-width: 701px)': {
            display: 'none'
        }
    },
    title: {
        color: 'white',
        fontFamily: 'inherit',
        fontSize: '1.1rem',
        fontWeight: 600,
        '@media (min-width: 1401px)': {
            fontSize: '1.375rem'
        }
    }
}));

const FloatingBar = ({ moduloId, modulos, open, fnToggleOpen }) => {
    const classes = useStyles();
    const modulo = modulos.filter(m => m.moduloId === parseInt(moduloId))[0];

    return (
        <SwipeableDrawer 
            anchor="left" 
            className={classes.swipeable}
            classes={{
                paper: classes.paper
            }}
            open={!open} 
            onOpen={fnToggleOpen} 
            onClose={fnToggleOpen}
        >
            <div className={classes.module}>
                {modulo ? <Avatar className={classes.avatar}><IconModulo className={classes.iconModule} name={modulo.imagen}/></Avatar> : <img alt="logo" className={classes.logo} src="/images/logo-small.png" />}
                <Typography className={classes.title}>
                    {modulo ? modulo.nombreCorto : 'SIGECO'}
                </Typography>
            </div>
            <TreeList id="floatingbar-app" open={true} fnToggleOpen={fnToggleOpen}/>
        </SwipeableDrawer>
    )
}

const mapStateToProps = state => ({
    moduloId: state.main.moduloId,
    modulos: state.main.modulos
});
  
export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(FloatingBar);