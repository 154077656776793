import axios from 'axios';
import { IdentityUrl } from '../../config';
import { closeMessageBox, closeAdvancedSearch, openAdvancedSearch, openMessageBox } from '../main/actions'
import {
    ERROR_PERMISSIONS_LIST,
    RECEIVE_PERMISSIONS_LIST,
    REQUEST_PERMISSIONS_LIST,
    RESTART_STATE,
    SET_ERROR,
    SET_MASK,
    SET_SELECTED_ROW,
    UPDATE_DATA_TABLE,
    UPDATE_FIELD_FILTER,
    UPDATE_TABLE_COLUMN
} from './types';

export const btnSave = () => (dispatch, getState) => {

    dispatch(openMessageBox({
        button: 'yesno',
        icon: 'question',
        message: '¿Desea guardar la información?',
        callback: btn => dispatch(onSave(btn))
    }));
};

export const onPermissionsList = () => async (dispatch, getState) => {
    if(getState().permissions.filters.nombreUsuario === '') {
        dispatch({ type: SET_ERROR, key: 'nombreUsuario', error: 'Este campo es requerido' });
    }

    if(getState().permissions.filters.dEmpresa === '') {
        dispatch({ type: SET_ERROR, key: 'dEmpresa', error: 'Este campo es requerido' })
    }

    if(getState().permissions.filters.dModulo === '') {
        dispatch({ type: SET_ERROR, key: 'dModulo', error: 'Este campo es requerido' })
    }

    if(getState().permissions.errors.nombreUsuario !== '' || getState().permissions.errors.dEmpresa !== '' || getState().permissions.errors.dModulo !== '') {
        return;
    }

    if(getState().permissions.table.loading) {
        return;
    }

    try {
        dispatch({ type: REQUEST_PERMISSIONS_LIST });

        let url = `${IdentityUrl}/api/v1/modulos/${getState().permissions.filters.moduloId}/autorizaciones/usuario?`
            + `usuarioId=${getState().permissions.filters.usuarioId}`
            + `&empresaId=${getState().permissions.filters.empresaId}`;

        let response = await axios.get(encodeURI(url));
        let data = response.data;
        
        dispatch({ type: RECEIVE_PERMISSIONS_LIST, payload: data });
    } catch (error) {
        dispatch({ type: ERROR_PERMISSIONS_LIST });
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    }
};

export const onSave = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });

            let filters = {
                ...getState().permissions.filters
            };

            let currentData = [
                ...getState().permissions.table.data
            ];

            let data = [];

            let fnSearch = (elements) => {
                for(var i = 0; i < elements.length; i++) {
                    if(elements[i].isEdit) {
                        data.push({ ...elements[i] });
                    }
                    if(elements[i].children) {
                        fnSearch(elements[i].children);
                    }
                }
            }
            
            fnSearch(currentData);

            let url = `${IdentityUrl}/api/v1/account/saveauthorization?userId=${filters.usuarioId}&companyId=${filters.empresaId}`;
            await axios.post(url, data, {headers: {'Content-Type': 'application/json'}});

            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'Registro realizado con exito.', 
                callback: () => {
                    dispatch(closeMessageBox());
                    dispatch(onPermissionsList());
                }
            }));
        } catch (error) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const onSearchCompanyClick = () => async (dispatch, getState) => {
    if(getState().permissions.filters.usuarioId === '') {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'warning', 
            message: 'Debe seleccionar un usuario.', 
            callback: () => dispatch(closeMessageBox())
        }));
        return;
    }

    let fields = [
        { 'dataKey': 'nombre', 'label': 'Empresa', 'type': 'string', 'search': true, 'width': 300 }
    ];

    try {
        dispatch({ type: SET_MASK, loading: true });
        
        let url = `${IdentityUrl}/api/v1/account/getcompanybyuser?usuarioId=${getState().permissions.filters.usuarioId}`;
        let response = await axios.get(encodeURI(url));
        let data = response.data;

        dispatch(openAdvancedSearch({
            data: data,
            fields: fields,
            title: 'Empresas',
            callback: (btn, result) => {
                dispatch(closeAdvancedSearch());
                if(btn === 'yes') {
                    dispatch({ type: SET_ERROR, key: 'dEmpresa', error: '' });
                    dispatch({ type: RECEIVE_PERMISSIONS_LIST, payload: [] });
                    dispatch(onUpdateFieldFilter('empresaId', result[0].empresaId));
                    dispatch(onUpdateFieldFilter('dEmpresa', result[0].nombre));
                }
            }
        }));
    } catch (error) {
        if(error.response) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onSearchModuleClick = () => async (dispatch) => {
    let fields = [
        { 'dataKey': 'nombre', 'label': 'Módulo', 'search': true, 'width': 250 }
    ];

    try {
        dispatch({ type: SET_MASK, loading: true });
        
        let url = `${IdentityUrl}/api/v1/account/getdetailworkspace`;
        let response = await axios.get(encodeURI(url));
        let data = response.data;

        dispatch(openAdvancedSearch({
            data: data,
            fields: fields,
            title: 'Módulos',
            callback: (btn, result) => {
                dispatch(closeAdvancedSearch());
                if(btn === 'yes') {
                    dispatch({ type: SET_ERROR, key: 'dModulo', error: '' });
                    dispatch({ type: RECEIVE_PERMISSIONS_LIST, payload: [] });
                    dispatch(onUpdateFieldFilter('moduloId', result[0].moduloId));
                    dispatch(onUpdateFieldFilter('dModulo', result[0].nombre));
                }
            }
        }));
    } catch (error) {
        if(error.response) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onSearchUserClick = () => async (dispatch) => {
    let fields = [
        { 'dataKey': 'nombreUsuario', 'label': 'Usuario', 'search': true, 'width': 115 },
        { 'dataKey': 'nombre', 'label': 'Nombres', 'search': true, 'width': 275 }
    ];

    try {
        dispatch({ type: SET_MASK, loading: true });

        let url = `${IdentityUrl}/api/v1/account/getonlyroleuser`;
        let response = await axios.get(encodeURI(url));
        let data = response.data;

        dispatch(openAdvancedSearch({
            data: data,
            fields: fields,
            paginated: true,
            title: 'Usuarios',
            callback: (btn, result) => {
                dispatch(closeAdvancedSearch());
                if(btn === 'yes') {
                    dispatch({ type: SET_ERROR, key: 'nombreUsuario', error: '' });
                    dispatch({ type: RECEIVE_PERMISSIONS_LIST, payload: [] });
                    dispatch(onUpdateFieldFilter('usuarioId', result[0].usuarioId));
                    dispatch(onUpdateFieldFilter('nombreUsuario', result[0].nombreUsuario + ' - ' + result[0].nombre));
                }
            }
        }));
    } catch (error) {
        if(error.response) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        } 
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
    let columns = getState().permissions.table.columns.map(item => {
        if(item.dataKey !== column.dataKey) {
            return item;
        }

        return {
            ...item,
            width: column.width
        };
    });

    dispatch({ type: UPDATE_TABLE_COLUMN, columns });
};

export const onUpdateDataTable = (option) => (dispatch, getState) => {
    let data = getState().permissions.table.data.map(item => { return { ...item }; });

    let fnSearch = (elements) => {
        for(var i = 0; i < elements.length; i++) {
            if(elements[i].optionId === option.optionId && elements[i].detailOptionId === option.detailOptionId ) {
                elements[i].allowed = option.allowed;
                elements[i].startDate = option.startDate;
                elements[i].endDate = option.endDate;
                elements[i].expanded = option.expanded
                return;
            }

            if(elements[i].children) {
                fnSearch(elements[i].children);
            }
        }
    }

    fnSearch(data);

    dispatch({ type: UPDATE_DATA_TABLE, data });
};

export const onUpdateFieldFilter = (key, value) => ({ type: UPDATE_FIELD_FILTER, key, value });

export const restartState = () => ({ type: RESTART_STATE });

export const setSelectedRow = (selected) => ({ type: SET_SELECTED_ROW, selected});
