import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';

import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';

import App from './App';
import configureStore from './store/configureStore';
import * as serviceWorker from './serviceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const history = createBrowserHistory({ basename: baseUrl });

const initialState = window.initialReduxState;
const store = configureStore(history, initialState);

const rootElement = document.getElementById('viewport');

ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <App />
        </MuiPickersUtilsProvider>
      </ConnectedRouter>
    </Provider>,
    rootElement);

serviceWorker.unregister();
