import axios from 'axios';
import { ClientId, IdentityUrl, RedirectPath } from '../../config';
import { getModules, themeChange } from '../main/actions'

import {
    RECEIVE_COMPANIES,
    RECEIVE_SESSION,
    SET_ACCOUNTS,
    SET_AVATAR,
    SET_DATA_COMPANY,
    SET_DATA_USER,
    SET_PARAMETERS
} from './types';

import {
    LOAD_FINISHED
} from '../main/types';

export const createInterceptor = () => (dispatch, getState) => {
    axios.interceptors.request.use(
        config => {
            if (window.SigecoAuth.access_token && !config.headers.Authorization) {
                config.headers.Authorization = `${window.SigecoAuth.token_type} ${window.SigecoAuth.access_token}`;
            }
            return config;
        },
        error => {
            Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        response => response,
        error => {
            if(error.response && error.response.status === 401) {
                dispatch(getSession());
                error.response.data = {
                    eventLogId: 0,
                    message: 'Su token a caducado, reintente por favor.'
                };
            }
            return Promise.reject(error);
        }
    );
}

export const getCompanies = () => async (dispatch, getState) => {
    if(getState().session.isLoadCompany) {
        return;
    }

    try {
        let url = `${IdentityUrl}/api/v1/empresas/sesion/listar`;
        let response = await axios.get(url);
        let data = response.data;
        let empresaId = data.filter(e => e.principal)[0].empresaId;

        dispatch({ type: SET_PARAMETERS, empresaId });
        dispatch({ type: RECEIVE_COMPANIES, payload: data });
    } catch (error) {
        console.log(`Error Lista Empresas: ${error}`);
    }
};

export const getSession = () => async (dispatch, getState) => {
    if(localStorage) {
        dispatch(themeChange(localStorage.getItem('sigecoColor') || 'orange'));
    }
    
    let urlParams = new URLSearchParams(window.location.search);
    let providerUri = `${IdentityUrl}/authorize`
        + `?client_id=${ClientId}`
        + `&response_type=token`
        + `&redirect_uri=${window.location.protocol}//${window.location.host}${RedirectPath}`
        + `&authuser=${urlParams.get('authuser') || '0'}`
        + (urlParams.get('company_code') == null ? '' : `&company_code=${urlParams.get('company_code')}`);
    let iframeId = 'silent-authorize-iframe';
    let old_iframe = document.getElementById(iframeId);

    if (old_iframe){
        document.body.removeChild(old_iframe);
    }
    //crear iframe
    const iframe = document.createElement('iframe');
    iframe.id = iframeId;
    iframe.className = 'silent-iframe';
    iframe.src = providerUri;
    document.body.appendChild(iframe);

    let listener = (event) => {
        let data = event.data;
        
        if (data.token_type) {
            dispatch({ type: RECEIVE_SESSION, accessToken: data.access_token, expiresIn: data.expires_in, tokenType: data.token_type });
            //Asignamos SigecoAuth
            window.SigecoAuth = {};
            window.SigecoAuth.access_token = data.access_token;
            window.SigecoAuth.token_type = data.token_type;
            window.SigecoAuth.identityUri = IdentityUrl;
            //
            setTimeout(() => {
                dispatch(getSession());
            }, (parseInt(data.expires_in, 10) - 30)*1000);
            window.removeEventListener('message', listener);
            if (document.body.contains(iframe)) {
                document.body.removeChild(iframe);
            }
            //
            if(getState().main.totalLoad === 0) {
                dispatch(getSesionData());
                dispatch(getModules());
            }
        }
        else {
            if(data.error) {
                console.log(data.error);
                if(data.status === 401) {
                    window.location.href = `${IdentityUrl}/oauth/v2/?continue=${encodeURIComponent(window.location.href)}`;
                }
            }
        }
    }
    window.addEventListener('message', listener);
};

export const getSesionData = () => async (dispatch) => {
    try {
        let url = `${IdentityUrl}/api/v1/account/datos`;
        let response = await axios.get(url);

        let data = response.data;

        dispatch({ type: SET_DATA_USER, usuarioId: data.usuario.usuarioId, usuario: data.usuario.nombreUsuario, nombre: data.usuario.nombre, avatar: data.usuario.avatar });
        dispatch({ type: SET_DATA_COMPANY, payload: data.empresa });
        dispatch({ type: LOAD_FINISHED });
        dispatch(onRequestAccounts());
    } catch (error) {
        console.log(`Error Usuario: ${error}`);
    }
};

export const onRequestAccounts = () => async (dispatch) => {
    try {
        let response = await fetch(`${IdentityUrl}/signin/accounts`, { method: 'GET', credentials: 'include'});
        let data = await response.json();

        dispatch({ type: SET_ACCOUNTS, payload: data });
    } catch (error) {
        console.log(`Error Cuentas: ${error}`);
    }
};

export const setAvatar = (avatar) => ({ type: SET_AVATAR, avatar });

export const UpdateCompanyPrincipal = (empresaId) => async (dispatch) => {
    try {
        dispatch({ type: SET_PARAMETERS, empresaId });

        let url = `${IdentityUrl}/api/v1/empresas/principal?empresaId=${empresaId}`;
        
        await axios.put(url);
    } catch (error) {
        console.log(`Error asigna principal: ${error}`);
    }
};