import React, { useState } from 'react';
import clsx from 'clsx';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/notify/actions';

const { KeyboardDatePicker } = window['@material-ui/pickers'];

const useStyles = makeStyles((theme) => ({
    datepicker: {
        width: '9rem'
    },
    icon: {
        color: '#3C4858',
        fontSize: '0.875rem',
        paddingRight: 6
    },
    iconCollapse: {
        color: '#3C4858',
        fontSize: '1.25rem'
    },
    root: {
        flex: '0 0 auto',
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem',
        }
    },
    title: {
        display: 'flex',
        fontSize: '0.875rem'
    }
}));

const NotificationFilter = () => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:701px)');
    const [selectedDate, handleDateChange] = useState(new Date());

    return(
        <div className={classes.root}>
            <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary
                    expandIcon={<Icon className={clsx("fas fa-angle-down", classes.iconCollapse)} />}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                >
                    <div className={classes.title}>
                        <Icon className={clsx("fas fa-filter", classes.icon)}/>
                        <div style={{ flexGrow: 1 }}>Filtros</div>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                            fullWidth
                            label="Título o contenido"
                            variant={matches ? "outlined" : "standard"}
                            req
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <KeyboardDatePicker
                            autoOk
                            className={classes.datepicker}
                            disableToolbar
                            variant={matches ? "inline" : "dialog"}
                            clearable
                            value={selectedDate}
                            placeholder="10/10/2018"
                            onChange={date => handleDateChange(date)}
                            minDate={new Date()}
                            format="dd/MM/yyyy"
                            label="Desde"
                            inputVariant={matches ? "outlined" : "standard"}
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <KeyboardDatePicker
                            autoOk
                            className={classes.datepicker}
                            disableToolbar
                            variant={matches ? "inline" : "dialog"}
                            clearable
                            value={selectedDate}
                            placeholder="10/10/2018"
                            onChange={date => handleDateChange(date)}
                            minDate={new Date()}
                            format="dd/MM/yyyy"
                            label="Hasta"
                            inputVariant={matches ? "outlined" : "standard"}
                            />
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    )
}

const mapStateToProps = state => ({
});
  
export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(NotificationFilter);