import React, { useEffect } from 'react';
import clsx from 'clsx';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../store/main/actions';

import NotificationFilter from '../components/notifications/NotificationFilter';
import NotificationList from '../components/notifications/NotificationList';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: '2rem',
    width: '2rem',
    '@media (min-width: 1401px)': {
        height: '2.5rem',
        width: '2.5rem'
    }
  },
  body: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    padding: '0 0.5rem 0.5rem',
    overflowY: 'auto',
    '@media (min-width: 701px)': {
      padding: '0 0.75rem 0.75rem'
    },
    '@media (min-width: 1401px)': {
      padding: '0 1rem 1rem'
    }
  },
  caption: {
    alignItems: 'center',
    color: '#3C4858',
    display: 'flex',
    marginLeft: '0.5rem'
  },
  footer: {
    alignItems: 'center',
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'flex-end',
    padding: '0 0.5rem 0.375rem',
    '@media (min-width: 701px)': {
      padding: '0 0.75rem 0.375rem',
    },
    '@media (min-width: 1401px)': {
      padding: '0 1rem 0.375rem'
    }
  },
  header: {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    height: '3rem',
    minHeight: '3rem',
    '@media (min-width: 1401px)': {
      height: '3.5rem',
      minHeight: '3.5rem',
    }
  },
  icon: {
    fontSize: '1rem',
    height: 'auto',
    width: 'auto',
    '@media (min-width: 1401px)': {
      fontSize: '1.25rem'
    }
  },
  root: {
    backgroundColor: theme.palette.primary.main + '14', 
    boxSizing: 'border-box',
    flexDirection: 'column',
    display: 'flex',
    height: '100%'
  },
  subtitle: {
    fontSize: '0.65rem',
    fontWeight: 400,
    '@media (min-width: 1401px)': {
      fontSize: '0.75rem',
    }
  },
  title: {
    fontSize: '0.8rem',
    fontWeight: 500,
    '@media (min-width: 1401px)': {
      fontSize: '1rem',
    }
  },
  toolbar: {
    display: 'flex',
    flex: '1 1 auto',
    paddingLeft: '0.5rem', 
    paddingRight: '0.5rem',
    '@media (min-width: 701px)': {
      paddingLeft: '0.75rem', 
      paddingRight: '0.75rem'
    },
    '@media (min-width: 1401px)': {
      paddingLeft: '1rem', 
      paddingRight: '1rem'
    }
  }
}));

const Notifications = ({ addOptionNav }) => {
  const classes = useStyles();
  const formState = ''

  useEffect(() => {
    addOptionNav('Notificaciones', '/notificaciones');
  // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.toolbar}>
          <Avatar className={classes.avatar}>
            <Icon className={clsx("fas fa-bell", classes.icon)}/>
          </Avatar>
          <div className={classes.caption}>
            <div>
              <div className={classes.title}>Búsqueda</div>
              <div className={classes.subtitle}>Notificaciones</div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.body}>
        <NotificationFilter />
        <NotificationList />
      </div>
      <div className={classes.footer}>
        <Button className={clsx("sigeco-boton", {[classes.hidden]: formState !== 'search'})} color="primary" size="small" variant="contained">
          <Icon className={clsx("sigeco-boton-icon", "fas fa-file-excel")}/>
          Exportar
        </Button>
        <Button className={clsx("sigeco-boton", {[classes.hidden]: formState !== 'search'})} color="primary" size="small" variant="contained">
          <Icon className={clsx("sigeco-boton-icon", "fas fa-search")}/>
          Buscar
        </Button>
        <Button className="sigeco-boton" color="primary" size="small" variant="contained">
          <Icon className={clsx("sigeco-boton-icon", "fas fa-door-open")}/>
          Salir
        </Button>
      </div>
    </div>
  );

  // return (
  //   <div style={{ height: 'calc(100% - 0px)', display: 'flex', backgroundColor: '#e9ebee', boxSizing: 'border-box', flexDirection: 'column'}}>
  //     <div style={{ height: 48, display: 'flex', minHeight: 48, alignItems: 'center', boxSizing: 'border-box' }}>
  //       <div style={{ paddingLeft: '1rem', paddingRight: '1rem', display: 'flex' }}>
  //         <Avatar color="#1976d2">
  //           <Icon className="fas fa-bell" style={{ fontSize: '1.25rem'}} />
  //         </Avatar>
  //         {/* <Icon className="fas fa-bell" style={{ fontSize: '1rem', paddingRight: 6, color: '#3C4858' }} /> */}
  //         <div style={{ marginLeft: 8 }}>
  //           <h4 style={{ fontSize: '1rem', margin: 0, color: '#3C4858', fontWeight: 500 }}>Búsqueda</h4>
  //           <h4 style={{ fontSize: '0.8125rem', margin: 0, color: '#3C4858', fontWeight: 400 }}>Notificaciones</h4>
  //         </div>
  //       </div>
  //     </div>
  //     <div style={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', paddingLeft: '1rem', paddingRight: '1rem', paddingBottom: '1rem' }}>
  //       <Paper style={{padding: '1rem', flex: '0 0 auto'}}>
  //         <div style={{ paddingBottom: '1rem' }}>
  //           <Icon className="fas fa-filter" style={{ fontSize: '1rem', paddingRight: 6, color: '#3C4858' }} />
  //           Filtros
  //         </div>
  //         <Grid container spacing={2}>
  //            <Grid item xs={4}>
  //              <TextField
  //                fullWidth
  //                label="Nombre"
  //                variant={matches ? "outlined" : "standard"}
  //              />
  //            </Grid>
  //            <Grid item xs={8}>
  //              <TextField
  //              fullWidth
  //                label="Descripción o detalle"
  //                variant={matches ? "outlined" : "standard"}
  //                req
  //              />
  //            </Grid>
  //            <Grid item xs={2}>
  //              <KeyboardDatePicker
  //                autoOk
  //                disableToolbar
  //                variant={matches ? "inline" : "dialog"}
  //                clearable
  //                value={selectedDate}
  //                placeholder="10/10/2018"
  //                onChange={date => handleDateChange(date)}
  //                minDate={new Date()}
  //                format="dd/MM/yyyy"
  //                label="Desde"
  //                inputVariant={matches ? "outlined" : "standard"}
  //                inpt
  //              />
  //            </Grid>
  //            <Grid item xs={2}>
  //              <KeyboardDatePicker
  //                autoOk
  //                disableToolbar
  //                variant={matches ? "inline" : "dialog"}
  //                clearable
  //                value={selectedDate}
  //                placeholder="10/10/2018"
  //                onChange={date => handleDateChange(date)}
  //                minDate={new Date()}
  //                format="dd/MM/yyyy"
  //                label="Hasta"
  //                inputVariant={matches ? "outlined" : "standard"}
  //              />
  //            </Grid>
  //          </Grid>
  //       </Paper>
  //       <Paper style={{padding: '1rem', marginTop: '1rem', flex: '1 1 auto'}}>
  //         <div style={{ paddingBottom: '1rem' }}>
  //           <Icon className="fas fa-th-list" style={{ fontSize: '1rem', paddingRight: 6, color: '#3C4858' }} />
  //           Resultados
  //         </div>
  //         <Paper className={classes.root}>
  //             <Table className={classes.table} aria-label="simple table" size="small">
  //               <TableHead>
  //                 <TableRow>
  //                   <TableCell>Dessert (100g serving)</TableCell>
  //                   <TableCell align="right">Calories</TableCell>
  //                   <TableCell align="right">Fat&nbsp;(g)</TableCell>
  //                   <TableCell align="right">Carbs&nbsp;(g)</TableCell>
  //                   <TableCell align="right">Protein&nbsp;(g)</TableCell>
  //                 </TableRow>
  //               </TableHead>
  //               <TableBody>
  //                 {rows.map(row => (
  //                   <TableRow key={row.name}>
  //                     <TableCell component="th" scope="row">
  //                       {row.name}
  //                     </TableCell>
  //                     <TableCell align="right">{row.calories}</TableCell>
  //                     <TableCell align="right">{row.fat}</TableCell>
  //                     <TableCell align="right">{row.carbs}</TableCell>
  //                     <TableCell align="right">{row.protein}</TableCell>
  //                   </TableRow>
  //                 ))}
  //               </TableBody>
  //             </Table>
  //           </Paper>
  //       </Paper>
  //     </div>
  //     <div style={{ paddingRight: '1rem' }} className="sigeco-botonera">
  //       <Button className={clsx("sigeco-boton", {[classes.hidden]: formState !== 'search'})} color="primary" size="small" variant="contained">
  //         <Icon className={clsx("sigeco-boton-icon", "fas fa-file-excel")}/>
  //         Exportar
  //       </Button>
  //       <Button className={clsx("sigeco-boton", {[classes.hidden]: formState !== 'search'})} color="primary" size="small" variant="contained">
  //         <Icon className={clsx("sigeco-boton-icon", "fas fa-search")}/>
  //           Buscar
  //       </Button>
  //       <Button className="sigeco-boton" color="primary" size="small" variant="contained">
  //         <Icon className={clsx("sigeco-boton-icon", "fas fa-door-open")}/>
  //         Salir
  //       </Button>
  //     </div>
  //   </div>
  // );

}

const mapStateToProps = state => ({
});

export default connect(
  mapStateToProps,
  dispatch => bindActionCreators(actionCreators, dispatch)
)(Notifications);