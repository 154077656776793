export const ADD_OPTION_NAV = 'main/add-option-nav';
export const ADVANCED_SEARCH_CLOSE = 'main/advanced-search-close';
export const ADVANCED_SEARCH_OPEN = 'main/advanced-search-open';
export const CHANGE_THEME = 'main/change-theme';
export const LOAD_FINISHED = 'main/load-finished';
export const MESSAGE_BOX_CLOSE = 'main/message-box-close';
export const MESSAGE_BOX_OPEN = 'main/message-box-open';
export const RECEIVE_MODULES_LIST = 'main/receive-modules-list';
export const RECEIVE_OPTIONS_LIST = 'main/receive-options-list';
export const RECEIVE_RESOURCES_LIST = 'main/receive-resources-list';
export const RECEIVE_ROUTES_LIST = 'main/receive-routes-list';
export const REMOVE_OPTION_NAV = 'main/remove-option-nav';
export const REQUEST_OPTIONS_LIST = 'main/request-options-list';
export const SET_MODULE = 'main/set-module';
export const SET_OPTION = 'main/set-option';
export const UPDATE_OPTION_NAV = 'main/update-option-nav';
