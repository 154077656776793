import {
    ERROR_PERMISSIONS_LIST,
    RECEIVE_PERMISSIONS_LIST,
    REQUEST_PERMISSIONS_LIST,
    RESTART_STATE,
    SET_ERROR,
    SET_MASK,
    SET_SELECTED_ROW,
    UPDATE_DATA_TABLE,
    UPDATE_FIELD_FILTER,
    UPDATE_TABLE_COLUMN
} from './types';

const initialState = {
    formState: 'search',
    loading: false,
    errors: {
        nombreUsuario: '',
        dEmpresa: '',
        dModulo: ''
    },
    filters: {
        usuarioId: '',
        nombreUsuario: '',
        empresaId: 0,
        dEmpresa: '',
        moduloId: 0,
        dModulo: ''
    },
    table: {
        data: [],
        loading: false,
        selected: null,
        columns: [
            { dataKey: 'name', label: 'Opción', width: 275 },
            { dataKey: 'allowed', label: 'Permitir', width: 70 },
            { dataKey: 'startDate', label: 'Desde', width: 115 },
            { dataKey: 'endDate', label: 'Hasta', width: 115 }
        ]
    }
};

export const permissionsReducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case ERROR_PERMISSIONS_LIST:
            return {
                ...state,
                table: {
                    ...state.table,
                    loading: false,
                    data: []
                }
            };
        case RECEIVE_PERMISSIONS_LIST:
            return {
                ...state,
                table: {
                    ...state.table,
                    data: action.payload,
                    loading: false
                }
            };
        case REQUEST_PERMISSIONS_LIST:
            return {
                ...state,
                table: {
                    ...state.table,
                    loading: true
                }
            };
        case RESTART_STATE:
            return {
                ...initialState
            };
        case SET_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.key]: action.error
                }
            };
        case SET_MASK:
            return {
                ...state,
                loading: action.loading
            };
        case SET_SELECTED_ROW:
            return {
                ...state,
                table: {
                    ...state.table,
                    selected: action.selected
                }
            };
        case UPDATE_DATA_TABLE:
            return {
                ...state,
                table: {
                    ...state.table,
                    data: action.data
                }
            };
        case UPDATE_FIELD_FILTER:
            return { 
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
        case UPDATE_TABLE_COLUMN:
            return {
                ...state,
                table: {
                    ...state.table,
                    columns: action.columns
                }
            };
        default:
            return state;
    }
};