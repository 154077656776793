import React from 'react';
import { useHistory, useLocation } from "react-router-dom";

import { FieldSet, Table } from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/user/actions';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    }, 
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const UserList = ({ columns, loadingStore, order, orderBy, store, onUpdateColumn, onUserList, setOrder, setPage, setPageSize, setSelectedRow }) => {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();

    const handleDoubleClick = (id) => {
        history.push({
            hash: location.hash,
            pathname: `/seguridad/usuario/${id}`,
            search: location.search
        });
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc', property);
        onUserList();
    };

    function handleChangePage(newPage) {
        setPage(newPage);
        onUserList();
    }
    
    function handleChangeRowsPerPage(event) {
        setPageSize(event.target.value);
        setPage(0);
    }
    
    return (
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados">
            <div className={classes.tableWrap}>
                <Table
                    columns={columns}
                    count={store.total}
                    loading={loadingStore}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    onRowClick={items => setSelectedRow(items)}
                    onRowDoubleClick={item => handleDoubleClick(item.usuarioId)}
                    onSort={datakey => handleRequestSort(datakey)}
                    onUpdateColumn={onUpdateColumn}
                    page={store.start/store.pageSize}
                    paginate
                    rowCount={store.data.length}
                    rowGetter={index =>  store.data[index] }
                    rowsPerPage={store.pageSize}
                    selected={store.selected}
                    sortBy={orderBy}
                    sortDirection={order}
                />
            </div>
        </FieldSet>
    )
}

const mapStateToProps = state => ({
    columns: state.user.table.columns,
    loadingStore: state.user.loadingStore,
    order: state.user.table.order,
    orderBy: state.user.table.orderBy,
    store: state.user.store
});
  
export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(UserList);