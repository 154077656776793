import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        height: '1.6rem',
        width: '1.6rem',
        '@media (min-width: 1401px)': {
            height: '2rem',
            width: '2rem'
        }
    },
    caption: {
        alignItems: 'center',
        color: '#3C4858',
        display: 'flex',
        flex: 1,
        marginLeft: '0.5rem'
    },
    favorite: {
        color: theme.palette.primary.main,
        fontSize: '0.8125rem',
        '@media (min-width: 1401px)': {
          fontSize: '1rem'
        }
    },
    header: {
        alignItems: 'center',
        boxSizing: 'border-box',
        display: 'flex',
        height: '2.4rem',
        minHeight: '2.4rem',
        '@media (min-width: 1401px)': {
          height: '3rem',
          minHeight: '3rem',
        }
    },
    icon: {
        fontSize: '0.8125rem',
        height: 'auto',
        width: 'auto',
        '@media (min-width: 1401px)': {
          fontSize: '1rem'
        }
    },
    subtitle: {
        fontSize: '0.55rem',
        fontWeight: 400,
        '@media (min-width: 1401px)': {
          fontSize: '0.6875rem',
        }
    },
    title: {
        fontSize: '0.7rem',
        fontWeight: 500,
        '@media (min-width: 1401px)': {
          fontSize: '0.875rem',
        }
    },
    toolbar: {
        display: 'flex',
        flex: '1 1 auto',
        paddingLeft: '0.5rem', 
        paddingRight: '0.5rem',
        '@media (min-width: 701px)': {
          paddingLeft: '0.75rem', 
          paddingRight: '0.75rem'
        },
        '@media (min-width: 1401px)': {
          paddingLeft: '1rem', 
          paddingRight: '1rem'
        }
    }
}));

const Header = props => {
    const classes = useStyles();
    const { favorite, favoriteEnabled, iconCls, subtitle, title } = props;
    const { onClick } = props;

    return (
        <div className={classes.header}>
            <div className={classes.toolbar}>
                <Avatar className={classes.avatar}>
                    <Icon className={clsx(iconCls, classes.icon)}/>
                </Avatar>
                <div className={classes.caption}>
                    <div>
                        <div className={classes.title}>{title}</div>
                        <div className={classes.subtitle}>{subtitle}</div>
                    </div>
                </div>
                {favoriteEnabled && (<IconButton className={clsx(favorite ? 'fas fa-star' : 'far fa-star' , classes.favorite)} onClick={onClick}/>)}
            </div>
        </div>
    );
}

Header.defaultProps = {
    favorite: false,
    favoriteEnabled: false,
    iconCls: '',
    subtitle: '',
    title: ''
};

Header.propTypes = {
    favorite: PropTypes.bool,
    favoriteEnabled: PropTypes.bool,
    iconCls: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
}

export default Header;