import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    contenido: {
        position: 'absolute',
        top: 'calc(50% - 20px)',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    rebote: {
        position: 'relative',
        display: 'inline-block',
        height: 37,
        width: 15,
        '&:before': {
            position: 'absolute',
            content: '""',
            display: 'block',
            top: 0,
            width: 15,
            height: 15,
            borderRadius: '50%',
            backgroundColor: theme.palette.primary.contrastText,
            transformOrigin: '50%',
            animation: '$bounce 500ms alternate infinite ease'
        }
    },
    root: {
        backgroundColor: theme.palette.primary.main,
        position: 'fixed',
        height: '100%',
        width: '100%'
    },
    texto: {
        color: theme.palette.primary.contrastText,
        display: 'inline-block',
        fontSize: '0.75rem',
        marginLeft: '0.5rem',
        '@media (min-width: 701px)': {
            fontSize: '0.875rem',
        },
        '@media (min-width: 1401px)': {
            fontSize: '1rem'
        }
    },
    '@keyframes bounce': {
        '0%': {
            top: 30,
            height: 5,
            borderRadius: '60px 60px 20px 20px',
            transform: 'scaleX(2)'
        },
        '35%': {
            height: 15,
            borderRadius: '50%',
            transform: 'scaleX(1)'
        },
        '100%': {
            top: 0
        }
    }
  }));

const Loading = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.contenido}>
                <div className={classes.rebote}/>
                <div className={classes.texto}>AHORA CARGANDO</div>
            </div>
        </div>
    );
}

export default Loading;