import React from 'react';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import Drawer from '@material-ui/core/Drawer';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/main/actions';

import IconModulo from '../../icons/icon';
import TreeList from './TreeList';

const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: '#fff',
        height: '1.6rem',
        marginLeft: '0.6rem',
        marginRight: '0.6rem',
        width: '1.6rem',
        '@media (min-width: 1401px)': {
            height: '2rem',
            marginLeft: '0.75rem',
            marginRight: '0.75rem',
            width: '2rem'
        }
    },
    drawer: {
        flexShrink: 0,
        height: '100%',
        position: 'fixed',
        whiteSpace: 'nowrap',
        width: 0,
        zIndex: 1300,
        '@media (min-width: 701px)': {
            width: '15rem',
        },
        '@media (min-width: 1401px)': {
            width: '18rem',
        }
    },
    drawerOpen: {
        overflow: 'hidden',
        width: 0,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: 150
        }),
        '@media (min-width: 701px)': {
            width: '15rem',
        },
        '@media (min-width: 1401px)': {
            width: '18rem',
        }
    },
    drawerClose: {
        backgroundSize: 'cover !important',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: 150
        }),
        overflowX: 'hidden',
        width: 0,
        '@media (min-width: 701px)': {
            width: '2.8rem'
        },
        '@media (min-width: 1401px)': {
            width: '3.5rem'
        }
    },
    iconModule: {
        color: theme.palette.primary.main,
        height: '1.2rem',
        '@media (min-width: 1401px)': {
            height: '1.5rem',
        }
    },
    logo: {
        height: '1.6rem',
        marginLeft: '0.6rem',
        marginRight: '0.6rem',
        '@media (min-width: 1401px)': {
            height: '2rem',
            marginLeft: '0.75rem',
            marginRight: '0.75rem'
        }
    },
    module: {
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        height: '2.25rem',
        minHeight: '2.25rem',
        position: 'relative',
        textAlign: 'center',
        '&:after': {
            right: '0.75rem',
            width: 'calc(100% - 1.5rem)',
            bottom: 0,
            height: '0.0625rem',
            content: '""',
            position: 'absolute',
            backgroundColor: 'hsla(0,0%,100%,.3)',
            '@media (min-width: 1401px)': {
                right: '0.9375rem',
                width: 'calc(100% - 1.875rem)',
            }
        },
        '@media (min-width: 1401px)': {
            height: '2.75rem',
            minHeight: '2.75rem',
        }
    },
    paper: {
        background: 'url(/images/sidebar.jpg) no-repeat',
        backgroundSize: '100% 100%',
        borderRight: 'none',
        position: 'relative'
    },
    title: {
        color: 'white',
        fontFamily: 'inherit',
        fontSize: '1.1rem',
        fontWeight: 600,
        '@media (min-width: 1401px)': {
            fontSize: '1.375rem'
        }
    }
}));

const SideBar = ({ moduloId, modulos, open }) => {
    const classes = useStyles();
    const [hover, setHover] = React.useState(false);
    const modulo = modulos.filter(m => m.moduloId === parseInt(moduloId))[0];

    return (
        <Drawer
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open && !hover
            })}
            classes={{
                paper: clsx(classes.paper, {
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open && !hover
                })
            }}
            open 
            variant="permanent"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <div className={classes.module}>
                {modulo ? <Avatar className={classes.avatar}><IconModulo className={classes.iconModule} name={modulo.imagen}/></Avatar> : <img alt="logo" className={classes.logo} src="/images/logo-small.png" />}
                <Typography className={classes.title}>
                    {modulo ? modulo.nombreCorto : 'SIGECO'}
                </Typography >
            </div>
            <TreeList id="sidebar-app" open={open} hover={hover}/>
        </Drawer>
    )
}

const mapStateToProps = state => ({
    moduloId: state.main.moduloId,
    modulos: state.main.modulos
});
  
export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(SideBar);