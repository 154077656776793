import axios from 'axios';
import { push } from 'connected-react-router';
import { IdentityUrl } from '../../config';
import { generateRandomCode } from '../../helpers/commons';
import { closeMessageBox, closeAdvancedSearch, openAdvancedSearch, openMessageBox } from '../main/actions'
import {
    CLEAN_FORM,
    DROP_COMPANY,
    ERROR_USERS_LIST,
    RECEIVE_USERS_LIST,
    REQUEST_USERS_LIST,
    RESTART_STATE,
    SELECT_PRINCIPAL,
    SET_ENTITY,
    SET_ERROR,
    SET_MASK,
    SET_ORDER,
    SET_PAGE,
    SET_PAGE_SIZE,
    SET_PASSWORD,
    SET_SELECTED_ROW,
    SET_STATE_FORM,
    UPDATE_FIELD_ENTITY,
    UPDATE_FIELD_FILTER,
    UPDATE_TABLE_COLUMN
} from './types';

export const cleanForm = () => ({ type: CLEAN_FORM });

export const getUser = (usuarioId) => async (dispatch, getState) => {
    try {
        dispatch({ type: SET_MASK, loading: true });

        let url = `${IdentityUrl}/api/v1/usuarios/${usuarioId}`;
        let response = await axios.get(encodeURI(url));
        let payload = {
            ...response.data,
            empresas: response.data.empresas.map(item => { 
                return {
                    empresaId: item.empresaId,
                    nombre: item.razonSocial,
                    principal: item.principal
                }
            })
        };
        
        dispatch({ type: SET_ENTITY, payload });
    } catch (error) {
        if(error.response.status === 404) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: 'No se encontro el registro solicitado.', 
                callback: () => { 
                    dispatch(closeMessageBox()); 
                    dispatch(push({
                        pathname: `/seguridad/usuario`,
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                }
            }));
        }
        else {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onDropCompany  = (empresaId) => ({ type: DROP_COMPANY, empresaId });

export const onExport = () => async (dispatch, getState) => {
    try {
        if(getState().user.store.data.length === 0) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'warning', 
                message: 'No hay información para exportar.', 
                callback: () => dispatch(closeMessageBox())
            }));
            return;
        }

        dispatch({ type: SET_MASK, loading: true });

        let url = `${IdentityUrl}/api/v1/usuarios/csv?`
            + `nombreUsuario=${getState().user.filters.usuario}`
            + `&nombre=${getState().user.filters.nombre}`;
        let response = await axios.get(encodeURI(url), { responseType: 'arraybuffer' });
        let csvHref = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');

        link.href = csvHref;
        link.setAttribute('download', 'usuarios.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onSearchCompanyClick = () => async (dispatch, getState) => {

    let fields = [
        { 'dataKey': 'nombre', 'label': 'Empresa', 'type': 'string', 'search': true, 'width': 300 }
    ];

    try {
        dispatch({ type: SET_MASK, loading: true });
        
        let url = `${IdentityUrl}/api/v1/account/getcompanybyuser`;
        let response = await axios.get(encodeURI(url));
        let data = response.data;

        dispatch(openAdvancedSearch({
            data: data,
            fields: fields,
            multiSelect: true,
            title: 'Empresas',
            callback: (btn, result) => {
                dispatch(closeAdvancedSearch());
                if(btn === 'yes') {

                    let empresas = getState().user.entity.empresas.map(item => ({ ...item}));

                    result.forEach(element => {
                        if(empresas.filter(f => f.empresaId === element.empresaId).length === 0) {
                            empresas.push({ empresaId: element.empresaId, nombre: element.nombre, principal: false });
                        }
                    });

                    dispatch({ type: SELECT_PRINCIPAL, payload: empresas });
                }
            }
        }));
    } catch (error) {
        if(error.response) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onSelectPrincipal = (empresa) => (dispatch, getState) => {
    let data = getState().user.entity.empresas.map(item => ({
        ...item,
        principal: item.empresaId === empresa.empresaId
    }));

    dispatch({ type: SELECT_PRINCIPAL, payload: data });
};

export const onUpdateFieldEntity = (key, value) => (dispatch) => {
    dispatch({ type: UPDATE_FIELD_ENTITY, key, value });

    if(key === 'nombre' || key === 'nombreUsuario' || key === 'correo') {
        dispatch({ type: SET_ERROR, key, error: '' });
    }
};

export const onUpdateFieldFilter = (key, value) => ({ type: UPDATE_FIELD_FILTER, key, value });

export const onUserList = () => async (dispatch, getState) => {
    if(getState().user.store.source) {
        getState().user.store.source.cancel();
    }

    let source = axios.CancelToken.source();
    let order = JSON.stringify([{
        property: getState().user.table.orderBy,
        direction: getState().user.table.order
    }]);
    dispatch({ type: REQUEST_USERS_LIST, source });

    try {
        let url = `${IdentityUrl}/api/v1/usuarios?`
            + `nombreUsuario=${getState().user.filters.usuario}`
            + `&nombre=${getState().user.filters.nombre}`
            + `&sort=${order}&pageSize=${getState().user.store.pageSize}&start=${getState().user.store.start}`;
        let response = await axios.get(encodeURI(url), { cancelToken: source.token });
        let data = response.data;

        dispatch({ type: RECEIVE_USERS_LIST, total: data.total, payload: data.data });
    } catch (error) {
        dispatch({ type: ERROR_USERS_LIST });
        if (!axios.isCancel(error)) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    }
};

export const restartState = () => ({ type: RESTART_STATE });

export const setFormState = (formState, id) => (dispatch, getState) => {
    switch (formState) {
        case 'edit':
            if(id && id !== getState().user.entity.usuarioId) {
                dispatch(getUser(id));
            }
            break;
        case 'new':
            dispatch(cleanForm());
            dispatch(setPassword(generateRandomCode(8)));
            break;
        case 'restart':
            if(id && id !== getState().user.entity.usuarioId) {
                dispatch(getUser(id));
            }
            dispatch(setPassword(generateRandomCode(8)));
            break;
        case 'search':
            dispatch(cleanForm());
            break;
        case 'view':
            if(id && id !== getState().user.entity.usuarioId) {
                dispatch(getUser(id));
            }
            break;
        default:
            break;
    }
    dispatch({ type: SET_STATE_FORM, formState });
}

export const setOrder = (order, orderBy) => ({ type: SET_ORDER, order, orderBy });

export const setPage = (page) => ({ type: SET_PAGE, page });

export const setPageSize = (pageSize) => ({ type: SET_PAGE_SIZE, pageSize });

export const setPassword = (password) => ({ type: SET_PASSWORD, password });

export const setSelectedRow = (selected) => ({ type: SET_SELECTED_ROW, selected});

export const btnDelete = () => (dispatch) => {
    dispatch(openMessageBox({
        button: 'yesno',
        icon: 'question',
        message: 'Se elimanara el registro permanentemente. ¿Desea continuar?',
        callback: btn => dispatch(onDelete(btn))
    }));
};

export const btnSave = () => (dispatch, getState) => {
    if(getState().user.entity.nombre === '') {
        dispatch({ type: SET_ERROR, key: 'nombre', error: 'Este campo es requerido' })
    }

    if(getState().user.entity.nombreUsuario === '') {
        dispatch({ type: SET_ERROR, key: 'nombreUsuario', error: 'Este campo es requerido' })
    }

    if(getState().user.entity.correo === '') {
        dispatch({ type: SET_ERROR, key: 'correo', error: 'Este campo es requerido' })
    }

    if(getState().user.entity.nroDocumento === '') {
        dispatch({ type: SET_ERROR, key: 'nroDocumento', error: 'Este campo es requerido' })
    }

    if(getState().user.entity.primerNombre === '') {
        dispatch({ type: SET_ERROR, key: 'primerNombre', error: 'Este campo es requerido' })
    }

    if(getState().user.entity.apellidoPaterno === '') {
        dispatch({ type: SET_ERROR, key: 'apellidoPaterno', error: 'Este campo es requerido' })
    }

    if(getState().user.errors.nombre !== '' || getState().user.errors.nombreUsuario !== '' || getState().user.errors.correo !== ''
        || getState().user.errors.nroDocumento !== '' || getState().user.errors.primerNombre !== '' || getState().user.errors.apellidoPaterno !== '') {
        return;
    }

    dispatch(openMessageBox({
        button: 'yesno',
        icon: 'question',
        message: '¿Desea guardar la información?',
        callback: btn => {
            if(getState().user.entity.usuarioId === '') {
                dispatch(onSave(btn));
            }
            else {
                dispatch(onUpdate(btn));
            }

        }
    }));
};

export const btnSearch = () => (dispatch) => {
    dispatch(setPage(0));
    dispatch(onUserList());
};

export const onDelete = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });

            let usuarioId = getState().user.entity.usuarioId;
            let url = `${IdentityUrl}/api/v1/usuarios/${usuarioId}`;
            await axios.delete(encodeURI(url));

            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'El registro se elimino correctamente.', 
                callback: () => { 
                    dispatch(closeMessageBox());
                    dispatch(push({
                        pathname: '/seguridad/usuario',
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                    dispatch(onUserList());
                }
            }));
        } catch (error) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const onSave = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });

            let usuario = {
                ...getState().user.entity,
                empresas: getState().user.entity.empresas.map(item => {
                    return {
                        empresaId: item.empresaId,
                        principal: item.principal
                    };
                })
            };

            let url = `${IdentityUrl}/api/v1/usuarios`;
            let response = await axios.post(url, usuario, {headers: {'Content-Type': 'application/json'}});
            let data = response.data;

            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'Registro realizado con exito.', 
                callback: () => { 
                    dispatch(closeMessageBox()); 
                    dispatch(push({
                        pathname: `/seguridad/usuario/${data}`,
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                }
            }));
        } catch (error) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const onUpdate = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });

            let usuario = {
                ...getState().user.entity,
                empresas: getState().user.entity.empresas.map(item => {
                    return {
                        empresaId: item.empresaId,
                        principal: item.principal
                    };
                })
            };

            if(getState().user.entity.clave !== '') {
                usuario.ReiniciarClave = true;
            }

            let url = `${IdentityUrl}/api/v1/usuarios`;
            let response = await axios.put(url, usuario, {headers: {'Content-Type': 'application/json'}});
            let data = response.data;

            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'Modificación realizado con exito.', 
                callback: () => { 
                    dispatch(closeMessageBox());
                    dispatch(push({
                        pathname: `/seguridad/usuario/${data}`,
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                    dispatch(getUser(data));
                }
            }));
        } catch (error) {
            if(error.response.status === 404) {
                dispatch(openMessageBox({ 
                    button: 'ok', 
                    icon: 'error', 
                    message: 'No se encontro el registro solicitado.', 
                    callback: () => { 
                        dispatch(closeMessageBox()); 
                        dispatch(push({
                            pathname: `/seguridad/usuario`,
                            search: getState().router.location.search,
                            hash: getState().router.location.hash
                        }));
                    }
                }));
            }
            else {
                dispatch(openMessageBox({ 
                    button: 'ok', 
                    icon: 'error', 
                    message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                    callback: () => dispatch(closeMessageBox())
                }));
            }
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
    let columns = getState().user.table.columns.map(item => {
        if(item.dataKey !== column.dataKey) {
            return item;
        }

        return {
            ...item,
            width: column.width
        }
    });

    dispatch({ type: UPDATE_TABLE_COLUMN, columns });
};