export const CLEAN_FORM = 'user/clean-form';
export const DROP_COMPANY = 'user/drop-company';
export const ERROR_USERS_LIST = 'user/error-users-list';
export const RECEIVE_USERS_LIST = 'user/receive-users-list';
export const REQUEST_USERS_LIST = 'user/request-users-list';
export const RESTART_ERRORS = 'user/restart-errors';
export const RESTART_STATE = 'user/restart-state';
export const SELECT_PRINCIPAL = 'user/select-principal';
export const SET_ENTITY = 'user/set-entity';
export const SET_ERROR = 'user/set-error';
export const SET_MASK = 'user/set-mask';
export const SET_ORDER = 'user/set-order';
export const SET_PAGE = 'user/set-page';
export const SET_PAGE_SIZE = 'user/set-page-size';
export const SET_PASSWORD = 'user/set-password';
export const SET_SELECTED_ROW = 'user/set-selected-row';
export const SET_STATE_FORM = 'user/set-state-form';
export const UPDATE_FIELD_ENTITY = 'user/update-field-entity';
export const UPDATE_FIELD_FILTER = 'user/update-field-filter';
export const UPDATE_TABLE_COLUMN = 'user/update-table-column';