import React, { useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../store/main/actions';

const useStyles = makeStyles(() => ({
    image: {
      width: '12rem',
      '@media (min-width: 701px)': {
        width: '15rem'
      },
      '@media (min-width: 1401px)': {
        width: '18.75rem'
      }
    },
    root: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      height: '100%'
    }
  })
);

const Home = ({ addOptionNav }) => {
    const classes = useStyles();

    useEffect(() => {
      addOptionNav('Inicio', '/', false, null, '/');
  // eslint-disable-next-line
  }, []);

    return (
        <div className={classes.root}>
            <img alt='logo-sigeco' className={classes.image} src='/images/logo.png'></img>
        </div>
    );
}

const mapStateToProps = state => ({
});

export default connect(
  mapStateToProps,
  dispatch => bindActionCreators(actionCreators, dispatch)
)(Home);